import React, { useEffect, useState } from "react";
import { DropdownButton, FormControl } from "@trimbleinc/modus-react-bootstrap";
import "./FormDropdown.scss";
import Dropdown from "@trimbleinc/modus-react-bootstrap/Dropdown";
import close from "../../../assets/images/close.svg";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { SEARCH_BOX_POSITION } from "../../../common/constants";

export interface IFormDropdownOption {
  id: string;
  name: string;
}

export interface IFormDropdown {
  id: string;
  title: string;
  options?: IFormDropdownOption[];
  selected?: string;
  showClearIcon?: boolean;
  enableSearch?: boolean;
  searchBoxPosition?: string;
  onClear?: () => void;
  onSelectOption: (option: IFormDropdownOption) => void;
}
const FormDropdown = (props: IFormDropdown) => {
  const {
    id,
    title,
    options = [],
    selected,
    showClearIcon,
    enableSearch,
    searchBoxPosition = SEARCH_BOX_POSITION.TOP,
    onSelectOption,
    onClear,
  } = props;

  const [selectedOption, setSelectedOption] = useState<IFormDropdownOption>();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<IFormDropdownOption[]>(options);

  const getOptionFromId = (id: string | undefined) => {
    if (id) {
      return options?.find((option: IFormDropdownOption) => option.id === id);
    }
  };

  const handleSelectedOption = (id: string | null) => {
    if (id) {
      const option = getOptionFromId(id);
      option && setSelectedOption(option);
      option && onSelectOption(option);
    }
  };

  const handleOnClear = (event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedOption(undefined);
    onClear?.();
    setSearchTerm("");
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const renderSearchBox = () =>
    enableSearch && (
      <div className="dropdown-search">
        <FormControl
          type="text"
          placeholder={i18nInstance.t("TTM.followup.search")}
          value={searchTerm}
          onChange={handleSearch}
          className="dropdown-search-input"
        />
      </div>
    );

  useEffect(() => {
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      setFilteredOptions(
        options.filter((option) => option.name.toLowerCase().includes(lowerSearchTerm)),
      );
    } else {
      setFilteredOptions(options);
    }
  }, [searchTerm, options]);

  useEffect(() => {
    selected && handleSelectedOption(selected);
    return () => {
      setSelectedOption(undefined);
    };
  }, [selected]);

  return (
    <DropdownButton
      variant="dropdown-basic-button"
      id={`dropdown-basic-button ${id}`}
      onToggle={(isOpen) => {
        if (!isOpen) {
          setSearchTerm("");
        }
      }}
      title={
        <div className="dropdown-title truncate-text">
          {selectedOption ? (
            <>
              <span title={selectedOption?.name} className="dropdown-value truncate-text">
                {selectedOption?.name}
              </span>
              {showClearIcon && (
                <img
                  src={close}
                  className="close-icon dropdown-clear"
                  title={i18nInstance.t("TTM.followup.clear")}
                  onClick={handleOnClear}
                />
              )}
            </>
          ) : (
            title
          )}
        </div>
      }
    >
      {searchBoxPosition === SEARCH_BOX_POSITION.TOP && renderSearchBox()}
      <div className="dropdown-options">
        {filteredOptions && filteredOptions.length > 0 ? (
          filteredOptions.map((option) => (
            <Dropdown.Item
              key={option.id}
              eventKey={option.id}
              className="dropdown-option truncate-text"
              onSelect={handleSelectedOption}
              title={option.name}
            >
              {option.name}
            </Dropdown.Item>
          ))
        ) : (
          <Dropdown.Item disabled>
            {i18nInstance.t("TTM.followup.error.noDataAvailable")}
          </Dropdown.Item>
        )}
      </div>
      {searchBoxPosition === SEARCH_BOX_POSITION.BOTTOM && renderSearchBox()}
    </DropdownButton>
  );
};

export default FormDropdown;
