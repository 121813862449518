import React from "react";
import { Route } from "react-router-dom";
import { PATH, PERMISSIONS } from "../../common/constants";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import Details from "../../components/templates/Details/Details";

const CrewModule = React.lazy(() => import("../../components/pages/Crew/Crew"));

export const CrewRoutes = () => {
  return (
    <Route
      path={PATH.CREW}
      element={
        <ProtectedRoute permission={PERMISSIONS.FOLLOWUP}>
          <CrewModule />
        </ProtectedRoute>
      }
    >
      <Route path={`${PATH.CREW}/:vehicleId`} element={<Details />} />
    </Route>
  );
};
