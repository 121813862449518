import { getAppConfig } from "./utils";

const getBaseURL = () => {
  return getAppConfig().api;
};

const getUMPBaseURL = () => {
  return getAppConfig().ump.api;
};

const getUiAssetsBaseURL = () => {
  return `${getAppConfig().cdn.baseUrl}assets/${getAppConfig().cdn.components.uiAssetsVersion}`;
};

const getUMPFilter = () => {
  return getAppConfig().ump.filter;
};

export const getFollowupURL = (suffix: string) => {
  return `${getBaseURL()}/followup/${suffix}`;
};

export const getMessagingURL = (suffix: string) => {
  return `${getBaseURL()}/messaging/${suffix}`;
};

export const getTraceURL = (suffix: string) => {
  return `${getBaseURL()}/history/${suffix}`;
};

export const getPermissionsURL = (suffix: string) => {
  return `${getUMPBaseURL()}/${suffix}?search=${getUMPFilter()}`;
};

export const getCategoryIconMappingURL = () => {
  return `${getUiAssetsBaseURL()}/products/places/place_category_icon_mapping.json`;
};

export const getCategoryIconURL = (iconName: string) => {
  return `${getUiAssetsBaseURL()}/images/icons/places/${iconName}.svg`;
};
