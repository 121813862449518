import React, { useState } from "react";
import "./TracesMapMobileContainer.scss";
import { DATE_DISPLAY_FORMAT, HISTORY_VIEWS, PATH } from "../../../../../common/constants";
import { useNavigate, useOutletContext } from "react-router-dom";
import { sendMonitoringLogs } from "../../../../../common/utils";
import { useTraceStore } from "../../../../../contexts/traces.context";
import { ITraceOutletContext } from "../../../../../models/TraceModel";
import { IMultiSelect } from "../../../../molecules/MultiSelect/MultiSelect";
import PanelHeader from "../../../../global/molecules/PanelHeader/PanelHeader";
import TraceTypeSearch from "../../../organisms/Traces/TraceTypeSearch/TraceTypeSearch";
import PanelContainer from "../../../../global/organisms/PanelContainer/PanelContainer";
import { find } from "lodash";
import useMobileDetect from "../../../../../hooks/useMobileDetect/useMobileDetect";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import timelineIcon from "../../../../../assets/images/timeline.svg";
import { useAppStore } from "../../../../../contexts/app.context";
import TaskInfo from "../../../../domain/Task/molecules/TaskInfo/TaskInfo";
import close from "../../../../../assets/images/close.svg";
import { isCustomTraceView } from "../../../../../common/tracesUtils";
import TraceViewDetailsPopup from "../../../molecules/TraceViewDetailsPopup/TraceViewDetailsPopup";
import { observer } from "mobx-react-lite";
import TracesMapMobileFooter from "../../../organisms/Traces/TracesMap/TracesMapMobileFooter/TracesMapMobileFooter";
import TracesMapContainer from "../TracesMapContainer/TracesMapContainer";

const TracesMapMobileContainer = () => {
  const appStore = useAppStore();
  const traceStore = useTraceStore();
  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  const mapContext = useOutletContext<ITraceOutletContext>();
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);

  const title = traceStore?.vehiclesList.find((v) => v.id == mapContext?.terminalId)?.title;

  const handleOnFilterOpen = () => {
    sendMonitoringLogs("HISTORY_FILTER_TRACE_TYPES_OPEN");
    setFilterOpen(true);
  };

  const handleOnFilterClose = (filteredTraceTypes?: IMultiSelect[]) => {
    setFilterOpen(false);
    sendMonitoringLogs("HISTORY_FILTER_TRACE_TYPES_CLOSE");
    if (filteredTraceTypes?.length === 0) {
      traceStore?.setTraceFilter({
        ...traceStore.traceFilter,
        traceTypes: [],
      });
    }
  };

  const handleOnBackClick = () => {
    navigate(PATH.TRACES);
  };

  const handleOnTimelineClick = () => {
    try {
      const date = traceStore?.traceFilter?.selectedDate;
      const terminalId = mapContext?.terminalId;
      sendMonitoringLogs("HISTORY_VEHICLES_LIST_SELECT");
      const path = `${PATH.TRACES}/${terminalId}/${date?.format(DATE_DISPLAY_FORMAT)}/timeline`;
      navigate(path);
    } catch (error) {
      console.log("handleOnTimelineClick ~ error:", error);
    }
  };

  const handleOnMapLoad = () => {
    setMapLoaded(true);
  };

  return (
    <div className="trace-map-container-mob">
      {isFilterOpen && (
        <div className="trace-filter-mob">
          <TraceTypeSearch onClose={handleOnFilterClose} />
        </div>
      )}
      <PanelContainer
        header={<PanelHeader title={title} onBackClick={handleOnBackClick} />}
        content={
          <>
            <TracesMapContainer onMapLoad={handleOnMapLoad} />
            {isMobile && mapLoaded && (
              <div className="timeline-btn-mob" onClick={handleOnTimelineClick}>
                <img src={timelineIcon} alt={i18nInstance.t("TTM.followup.timeline")} />
                {i18nInstance.t("TTM.followup.timeline")}
              </div>
            )}
          </>
        }
        footer={<TracesMapMobileFooter handleOnFilterOpen={handleOnFilterOpen} />}
      />
      {mapContext?.selectedTrace && (
        <div className="mobile-popup">
          <TraceViewDetailsPopup
            trace={mapContext?.selectedTrace}
            terminalId={mapContext?.terminalId}
            traceTypeName={mapContext?.selectedTrace?.typeName}
            activityTypes={appStore?.activityTypes ? appStore.activityTypes : []}
            traceTypes={traceStore?.traceTypes ? traceStore.traceTypes : []}
            selectedView={traceStore?.selectedView}
            showCustomView={isCustomTraceView(mapContext?.selectedTrace?.type, traceStore)}
            showTraceProperties={true}
            onClose={() => mapContext?.handleSelectedTrace(null)}
          />
        </div>
      )}
      {mapContext?.selectedTask && (
        <div className="mobile-popup">
          <img
            src={close}
            className="task-details-close-icon"
            alt={i18nInstance.t("TTM.followup.close")}
            onClick={() => mapContext?.handleSelectedTask(null)}
          />
          <TaskInfo
            taskName={mapContext?.selectedTask?.name}
            tripName={mapContext?.selectedTask?.trip?.name}
            activityName={
              find(appStore?.activityTypes, { code: mapContext?.selectedTask?.typeId })?.name
            }
            location={mapContext?.selectedTask?.location}
            timestamp={mapContext?.selectedTask?.startTime}
            order={+(mapContext?.selectedTask?.order ?? "")}
            status={mapContext?.selectedTask?.status}
          />
        </div>
      )}
    </div>
  );
};

export default observer(TracesMapMobileContainer);
