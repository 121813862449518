import React, { useEffect, useRef, useState } from "react";
import "./TracesMapLayersContainer.scss";
import { observer } from "mobx-react-lite";
import { useOutletContext } from "react-router-dom";
import { Feature, FeatureCollection } from "@trimblemaps/trimblemaps-js/geojson";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import TrimbleMaps, { LngLatBounds } from "@trimblemaps/trimblemaps-js";
import { HISTORY_VIEWS, TOAST_STYLE } from "../../../../../common/constants";
import {
  getTraceFeature,
  getFeaturesByWeightage,
  updateFeaturesWithWeightage,
  getUpdatedGeoJSON,
} from "../../../../../common/tracesUtils";
import { getAppConfig } from "../../../../../common/utils";
import { useAppStore } from "../../../../../contexts/app.context";
import { useTraceStore } from "../../../../../contexts/traces.context";
import { ITraceOutletContext, ITrace } from "../../../../../models/TraceModel";
import TraceService from "../../../../../services/Trace.service";
import { TracesMapService } from "../../../../organisms/MapView/Services/TracesMapService/TracesMapService";
import TaskLayerWrapper from "../../../organisms/Traces/TracesMap/TracesMapLayers/TaskLayerWrapper/TaskLayerWrapper";
import TracesCustomIconLayer from "../../../organisms/Traces/TracesMap/TracesMapLayers/TracesCustomIconLayer/TracesCustomIconLayer";
import TracesLayer from "../../../organisms/Traces/TracesMap/TracesMapLayers/TracesLayer/TracesLayer";
import TracesViewSelect from "../../../molecules/TracesViewSelect/TracesViewSelect";

export interface IProps {
  map?: TrimbleMaps.Map;
  tracesMapService?: TracesMapService;
}

const TracesMapLayersContainer = (props: IProps) => {
  const { map, tracesMapService } = props;

  const appConfig = getAppConfig();
  const traceStore = useTraceStore();
  const appStore = useAppStore();
  const traceService = new TraceService();
  const mapContext = useOutletContext<ITraceOutletContext>();

  const [traceFeature, setTraceFeature] = useState<Feature>();
  const masterTraceFeatures = useRef<Feature[]>([]);
  const lngLatBounds = useRef<LngLatBounds | null>(null);

  const [highWeightageGeoJSON, setHighWeightageGeoJSON] = useState<Record<
    string,
    FeatureCollection
  > | null>(null);
  const [lowWeightageGeoJson, setLowWeightageGeoJson] = useState<FeatureCollection | null>(null);

  /**************  LAYERS HANDLER METHODS **************/
  const updateFitBounds = (bounds: LngLatBounds) => {
    if (!lngLatBounds.current) {
      lngLatBounds.current = new LngLatBounds(
        new TrimbleMaps.LngLat(bounds.getWest(), bounds.getSouth()),
        new TrimbleMaps.LngLat(bounds.getEast(), bounds.getNorth()),
      );
    } else {
      lngLatBounds.current.extend(bounds);
    }
    tracesMapService?.fitBounds(lngLatBounds.current);
  };

  /**************  TRACES VIEW SELECT METHODS **************/
  const getJsonConfig = async (selectedView: string) => {
    if (
      selectedView !== HISTORY_VIEWS.DEFAULT &&
      !traceStore?.selectedViewJsonConfig.has(selectedView)
    ) {
      const data = (await traceService.getHistoryViewData(selectedView)).data;
      traceStore?.setSelectedViewJsonConfig(selectedView, data);
    }
    return traceStore?.selectedViewJsonConfig?.get(selectedView);
  };

  const computeHighWeightageGeoJSON = async (selectedView: string, config: any) => {
    const comparator = (weightage: number) => weightage == 3;
    const highWeightageFeatures = getFeaturesByWeightage(
      masterTraceFeatures.current,
      config,
      comparator,
    );
    return highWeightageFeatures
      ? getUpdatedGeoJSON(
          highWeightageFeatures,
          selectedView,
          appStore?.activityTypes || [],
          traceStore?.traceTypes || [],
          config,
        )
      : null;
  };

  const handleCustomView = async (selectedView: string, config: any) => {
    if (!highWeightageGeoJSON?.hasOwnProperty(selectedView)) {
      const updatedGeoJSON = await computeHighWeightageGeoJSON(selectedView, config);
      if (updatedGeoJSON) {
        setHighWeightageGeoJSON({
          ...highWeightageGeoJSON,
          [selectedView]: updatedGeoJSON,
        });
      }
    }
  };

  const handleTraceLayer = (config: any) => {
    const comparator = (weightage: number) => weightage < 3;
    const lowWeightageFeatures = getFeaturesByWeightage(
      masterTraceFeatures.current,
      config,
      comparator,
    );
    const updatedTraceFeatures = updateFeaturesWithWeightage(lowWeightageFeatures, config);
    setLowWeightageGeoJson(updatedTraceFeatures);
  };

  const handleTraceViewSelect = async (selectedView: string) => {
    try {
      mapContext?.handleHighlightedTraces([]);
      if (selectedView == HISTORY_VIEWS.DEFAULT) {
        setLowWeightageGeoJson({
          type: "FeatureCollection",
          features: masterTraceFeatures.current,
        });
        return;
      }
      const config = await getJsonConfig(selectedView);
      handleTraceLayer(config);
      handleCustomView(selectedView, config);
      mapContext?.handleShowToast({
        showToast: true,
        message: i18nInstance.t("TTM.followup.traces.selectedView", {
          selectedView: i18nInstance.t(`TTM.followup.${traceStore?.selectedView}`),
        }),
        style: TOAST_STYLE.PRIMARY,
        delay: 2000,
      });
    } catch (error) {
      console.log("handleTraceViewSelect ~ error:", error);
    }
  };

  const recomputeHighWeightageGeoJSON = async () => {
    try {
      setHighWeightageGeoJSON(null);
      if (!traceStore?.selectedViewJsonConfig) return;
      const geoJSONPromises = Array.from(traceStore.selectedViewJsonConfig).map(
        async ([view, config]) => {
          const updatedGeoJSON = await computeHighWeightageGeoJSON(view, config);
          return updatedGeoJSON ? { view, updatedGeoJSON } : null;
        },
      );
      const results = (await Promise.all(geoJSONPromises)).filter((result) => result !== null);
      const translatedGeoJSON = results.reduce((res, { view, updatedGeoJSON }) => {
        res[view] = updatedGeoJSON;
        return res;
      }, {} as Record<string, FeatureCollection>);
      setHighWeightageGeoJSON(translatedGeoJSON);
    } catch (error) {
      console.log("recomputeHighWeightageGeoJSON ~ error:", error);
    }
  };

  /**************  DATA HANDLER METHODS **************/
  const handleTraceData = (trace: ITrace) => {
    const feature = getTraceFeature(trace);
    if (feature) {
      setTraceFeature(feature);
      masterTraceFeatures.current = [...masterTraceFeatures?.current, feature];
    }
  };

  const onCleanUp = () => {
    setTraceFeature(undefined);
    mapContext?.handleResetTraces();
    masterTraceFeatures.current = [];
    setLowWeightageGeoJson(null);
    setHighWeightageGeoJSON(null);
  };

  /**************  LIFE CYCLE METHODS **************/
  useEffect(() => {
    if (traceStore?.selectedView && mapContext?.isDataLoaded) {
      handleTraceViewSelect(traceStore.selectedView);
    }
  }, [mapContext?.isDataLoaded, traceStore?.selectedView]);

  useEffect(() => {
    if (mapContext?.trace) {
      handleTraceData(mapContext.trace);
    }
  }, [mapContext?.trace]);

  useEffect(() => {
    mapContext?.resetTraces && onCleanUp();
  }, [mapContext?.resetTraces]);

  useEffect(() => {
    if (traceStore?.traceFilter?.terminalId && traceStore?.traceFilter?.selectedDate) {
      lngLatBounds.current = null;
    }
  }, [traceStore?.traceFilter?.terminalId, traceStore?.traceFilter?.selectedDate]);

  useEffect(() => {
    recomputeHighWeightageGeoJSON();
  }, [appStore?.activityTypes]);

  return (
    <div className="layers-container">
      <div className="task-layer-container">
        {appConfig?.showTaskTimeline && (
          <TaskLayerWrapper map={map} updateFitBounds={updateFitBounds} />
        )}
        <TracesViewSelect />
      </div>

      <TracesCustomIconLayer
        map={map}
        geoJSON={traceStore?.selectedView ? highWeightageGeoJSON?.[traceStore?.selectedView] : null}
        tracesMapService={tracesMapService}
        resetLayer={mapContext?.resetTraces}
        timeRange={mapContext?.timeRange}
        handleOnTraceSelect={mapContext?.handleSelectedTrace}
      />

      <TracesLayer
        map={map}
        feature={traceFeature}
        tracesMapService={tracesMapService}
        resetLayer={mapContext?.resetTraces}
        isDataLoaded={mapContext?.isDataLoaded}
        selectedViewGeoJSON={lowWeightageGeoJson}
        selectedTrace={mapContext?.selectedTrace}
        timeRange={mapContext?.timeRange}
        masterTraceFeatures={masterTraceFeatures.current}
        highlightedTraceIds={mapContext?.highlightedTraceIds}
        updateFitBounds={updateFitBounds}
        handleSelectedTask={mapContext?.handleSelectedTask}
        handleOnTraceSelect={mapContext?.handleSelectedTrace}
      />
    </div>
  );
};

export default observer(TracesMapLayersContainer);
