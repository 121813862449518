import React from "react";
import { Outlet } from "react-router-dom";
import TwoColumnTemplate from "../../../../global/template/TwoColumnTemplate/TwoColumnTemplate";
import TraceVehiclesPanel from "../../../organisms/Traces/TracesVehicles/TraceVehiclesPanel/TraceVehiclesPanel";

const TracesWrapper = () => {
  return (
    <TwoColumnTemplate
      leftPane={{
        component: <TraceVehiclesPanel />,
        colSpan: 2,
      }}
      rightPane={{
        component: <Outlet />,
        colSpan: 10,
      }}
    />
  );
};

export default TracesWrapper;
