import React, { useEffect, useState } from "react";
import "./TraceVehiclesList.scss";
import moment from "moment";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { sendMonitoringLogs } from "../../../../../../common/utils";
import TraceService from "../../../../../../services/Trace.service";
import FleetIcon from "../../../../../molecules/FleetIcon/FleetIcon";
import { IHistoryVehicle } from "../../../../../../models/TraceModel";
import { useTraceStore } from "../../../../../../contexts/traces.context";
import { PATH, DATE_DISPLAY_FORMAT } from "../../../../../../common/constants";
import useMobileDetect from "../../../../../../hooks/useMobileDetect/useMobileDetect";
import { useEventSource } from "../../../../../../hooks/useEventSource/useEventSource";
import AutoComplete, { IListData } from "../../../../../molecules/AutoComplete/AutoComplete";

const TraceVehiclesList = () => {
  const isMobile = useMobileDetect();
  const params = useParams();
  const traceService = new TraceService();
  const traceStore = useTraceStore();
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(true);
  const [selectedUnitId, setSelectedUnitId] = useState<string>();
  const [traceVehiclesList, setTraceVehiclesList] = useState<IListData[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>();
  const formatData = (data: IHistoryVehicle) => {
    const id = data?.vehicle?.id || "";
    const icon = data?.vehicle?.type ? <FleetIcon type={data.vehicle.type} /> : undefined;
    const title = data?.vehicle?.name || "";
    const lastActiveDate = data?.lastActiveTimeStamp || null;
    //setting additional props to ListData interface
    const additionalProps = new Map<string, any>();
    return { id, icon, title, additionalProps };
  };
  const handleListSelect = (listItem: IListData) => {
    try {
      const date = traceStore?.traceFilter?.selectedDate || moment();

      sendMonitoringLogs("HISTORY_VEHICLES_LIST_SELECT");
      navigate(`${PATH.TRACES}/${listItem.id}/${date.format(DATE_DISPLAY_FORMAT)}`);
    } catch (error) {
      console.log("TraceVehiclesList ~ handleListSelect ~ error:", error);
    }
  };
  const [source, data, isCompleted, error, consolidatedData, refreshOnDemand] = useEventSource({
    src: {
      url: traceService.getTraceVehiclesList(),
    },
  });
  const handleListResponse = (data: IHistoryVehicle) => {
    if (data) {
      const formattedData = formatData(data);
      if (!isMobile && !params?.unitId && traceVehiclesList?.length === 0) {
        handleListSelect(formattedData);
      }
      setTraceVehiclesList([...traceVehiclesList, formattedData]);
    }
  };
  const handleParams = () => {
    if (params?.unitId) {
      const vehicleData = traceVehiclesList.find(
        (vehicle) => vehicle.id == params?.unitId,
      ) as IListData;
      if (vehicleData) {
        handleListSelect(vehicleData);
      } else if (traceVehiclesList.length > 0 && traceVehiclesList[0]) {
        handleListSelect(traceVehiclesList[0]);
      }
    }
  };
  const refreshData = () => {
    traceStore?.setShowError(false);
    setShowLoading(true);
    traceStore?.setVehiclesList([]);
    refreshOnDemand?.();
    sendMonitoringLogs("HISTORY_VEHICLES_LIST_REFRESH");
  };
  useEffect(() => {
    if (data) {
      setShowLoading(false);
      traceStore?.setShowError(false);
      handleListResponse(data);
    }
  }, [data]);
  useEffect(() => {
    if (error) {
      traceStore?.setShowError(true);
    }
  }, [error]);
  useEffect(() => {
    if (isCompleted) {
      setShowLoading(false);
      if (traceVehiclesList.length === 0) {
        setErrorMsg("TTM.followup.error.noDataAvailable");
        traceStore?.setVehiclesList([]);
      } else {
        traceStore?.setVehiclesList(traceVehiclesList);
      }
      handleParams();
    }
  }, [isCompleted]);
  useEffect(() => {
    setSelectedUnitId(params?.unitId);
    if (!isMobile && !params?.unitId && traceVehiclesList.length > 0) {
      traceVehiclesList[0] && handleListSelect(traceVehiclesList[0]);
    }
  }, [params?.unitId]);
  return (
    <div className="list-block">
      {errorMsg ? (
        <div className="error-msg">{i18nInstance.t(errorMsg)}</div>
      ) : (
        <AutoComplete
          showError={traceStore?.showError}
          showLoader={showLoading}
          reloadData={refreshData}
          list={traceVehiclesList}
          onListSelect={handleListSelect}
          rowHeight={46}
          selectedItemId={selectedUnitId}
        />
      )}
    </div>
  );
};
export default observer(TraceVehiclesList);
