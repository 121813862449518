import React, { useEffect, useRef, useState } from "react";
import { find } from "lodash";
import "./TracesListPanel.scss";
import { observer } from "mobx-react-lite";
import { useOutletContext } from "react-router-dom";
import { useVirtualizer } from "@tanstack/react-virtual";
import up from "../../../../../../assets/images/arrow_up.svg";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import infoIcon from "../../../../../../assets/images/info.svg";
import DateTime from "../../../../../molecules/DateTime/DateTime";
import { sendMonitoringLogs } from "../../../../../../common/utils";
import TraceTypeSearch from "../../TraceTypeSearch/TraceTypeSearch";
import { DATE_TIME_FORMAT } from "../../../../../../common/constants";
import errorIcon from "../../../../../../assets/images/error_icon.svg";
import { useTraceStore } from "../../../../../../contexts/traces.context";
import SkeletonComponent from "../../../../../atoms/Skeleton/SkeletonComponent";
import { ITrace, ITraceOutletContext } from "../../../../../../models/TraceModel";
import TraceDetails from "../../../../../domain/Trace/organisms/TraceDetails/TraceDetails";
import { useDidMountEffect } from "../../../../../../hooks/useDidMountEffect/useDidMountEffect";
import { useAppStore } from "../../../../../../contexts/app.context";

const TracesListPanel = () => {
  const traceStore = useTraceStore();
  const appStore = useAppStore();
  const parentRef = useRef<HTMLDivElement>(null);
  const traceContext = useOutletContext<ITraceOutletContext>();

  const [activeTraceIds, setActiveTraceIds] = useState<string[]>([]);
  const [tracesList, setTracesList] = useState<ITrace[]>([]); //Traces data to be displayed in the list.
  const tracesListRef = useRef<ITrace[]>([]); //Master data for filtering and view change.

  /**************  DYNAMIC VIRTUALIZER METHODS **************/
  const virtualizer = useVirtualizer({
    count: tracesList?.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 80,
    overscan: 5,
  });

  const items = virtualizer?.getVirtualItems();

  /**************  SCROLL HANDLER METHODS **************/
  const scrollToTrace = (traceId: string | number) => {
    const index = tracesList.findIndex((trace) => trace.id == traceId);
    if (index >= 0 && parentRef && parentRef.current) {
      virtualizer.scrollToIndex(index);
    }
  };

  const scrollToTop = () => {
    try {
      virtualizer?.scrollToIndex?.(0);
    } catch (error) {
      console.log("error:", error);
    }
  };

  /**************  HANDLER METHODS **************/
  const getTraceName = (index: number) => {
    const traceName = find(traceStore?.traceTypes, {
      traceType: tracesList[index]?.type,
    })?.name;

    const activityName = appStore?.activityTypes?.find(
      (a) => a.id === tracesList[index]?.eventTypeId,
    )?.name;
    if (activityName) {
      return `${traceName} - ${activityName}`;
    }
    return traceName;
  };

  const filterTracesByTimeRange = () => {
    const timeRange = traceContext?.timeRange;
    if (!timeRange) return;
    const filteredTraces = tracesListRef.current?.filter((trace) => {
      return trace.timestamp >= timeRange?.startTime && trace.timestamp <= timeRange?.endTime;
    });
    setTracesList(filteredTraces);
  };

  const handleOnClick = (selectedId: string) => {
    const isActive = activeTraceIds.includes(selectedId);
    isActive
      ? setActiveTraceIds(activeTraceIds.filter((activeId) => activeId !== selectedId))
      : setActiveTraceIds([...activeTraceIds, selectedId]);
    sendMonitoringLogs(
      "HISTORY_TRACESLIST_TRACE_TOGGLE_ACCORDION",
      isActive ? "COLLAPSE" : "EXPAND",
    );
  };

  const handleViewChange = (config: any) => {
    if (config) {
      const filteredTraces = traceContext?.masterTracesList.filter((trace) => config?.[trace.type]);
      setTracesList(filteredTraces);
      tracesListRef.current = filteredTraces;
    } else {
      setTracesList(traceContext?.masterTracesList);
      tracesListRef.current = traceContext?.masterTracesList;
    }
  };

  useEffect(() => {
    if (tracesList && tracesList.length > 0) {
      scrollToTop();
    }
  }, [tracesList]);

  /**************  LIFE CYCLE METHODS **************/
  /**
   * This useEffect is for updating the selected view's traces list
   * Note: nested objects or arrays as values, MobX will not automatically observe changes
   * Hence, we need to observe the nested objects or arrays manually by passing them as dependencies
   */
  useEffect(() => {
    if (traceContext?.isDataLoaded) {
      const config = traceStore?.selectedViewJsonConfig.get(traceStore.selectedView);
      handleViewChange(config);
    }
  }, [traceContext?.isDataLoaded, traceStore?.selectedViewJsonConfig.get(traceStore.selectedView)]);

  useEffect(() => {
    filterTracesByTimeRange();
  }, [traceContext?.timeRange]);

  useEffect(() => {
    if (traceContext?.resetTraces) {
      setActiveTraceIds([]);
      tracesListRef.current = [];
      setTracesList([]);
    }
  }, [traceContext?.resetTraces]);

  /**
   * This block scrolls to selected trace, but only if the data is loaded and there are traces available.
   */
  useDidMountEffect(() => {
    try {
      if (traceContext?.isDataLoaded && tracesList.length > 0) {
        const traceIdToScroll = traceContext?.selectedTrace?.id;
        traceIdToScroll && scrollToTrace(traceIdToScroll);
      }
    } catch (error) {
      console.log(" error:", error);
    }
  }, [traceContext?.selectedTrace]);

  return (
    <div className="block-container flex-column traces-list">
      <TraceTypeSearch />
      {traceContext?.error && (
        <div className="block-container d-flex flex-column justify-content-center">
          <div className="m-2 p-3 trace-error">
            <img className="trace-error-icon" src={errorIcon} />
            {`${i18nInstance.t("TTM.followup.generic.error")}`}
          </div>
        </div>
      )}
      {!traceContext?.error && !traceContext?.isDataLoaded && (
        <div className="m-2">
          <SkeletonComponent count={2} height={35} width={"100%"} />
        </div>
      )}
      {!traceContext?.error && traceContext?.isDataLoaded && (
        <div
          className="block-container d-flex flex-column justify-content-center overflow-auto"
          onMouseLeave={() => traceContext?.handleHighlightedTraces([])}
        >
          {tracesList.length > 0 ? (
            <div ref={parentRef} className="traces-list-container">
              <div
                style={{
                  position: "relative",
                  height: virtualizer?.getTotalSize(),
                }}
              >
                <div
                  className="traces-list-panel"
                  style={{
                    transform: `translateY(${items?.[0]?.start ?? 0}px)`,
                  }}
                >
                  {items?.map((virtualRow) => (
                    <div
                      className={`traces-list-row cursor-pointer ${
                        traceContext?.selectedTrace?.id == tracesList[virtualRow.index]?.id
                          ? "traces-list-row-selected"
                          : ""
                      } ${
                        traceContext?.highlightedTraceIds?.includes(
                          tracesList[virtualRow.index]?.id.toString(),
                        )
                          ? "traces-list-row-highlight"
                          : ""
                      }`}
                      key={virtualRow.key}
                      data-index={virtualRow.index}
                      ref={(el) => virtualizer.measureElement(el)}
                      onMouseOver={() => {
                        traceContext?.handleHighlightedTraces([
                          tracesList[virtualRow.index].id.toString(),
                        ]);
                      }}
                    >
                      <div className="traces-list-item">
                        <div
                          className="traces-list-info"
                          onClick={() => {
                            traceContext?.handleSelectedTrace(tracesList[virtualRow.index]);
                            sendMonitoringLogs("HISTORY_TRACESLIST_TRACE_SELECT");
                          }}
                        >
                          <span
                            className="trace-title truncate-text"
                            title={getTraceName(virtualRow.index)}
                          >
                            {getTraceName(virtualRow.index)}
                          </span>
                          <span className="trace-timestamp">
                            <DateTime
                              dateTime={tracesList[virtualRow.index]?.timestamp}
                              displayFormat={DATE_TIME_FORMAT}
                            />
                          </span>
                        </div>
                        <div
                          className="trace-accordion-arrow"
                          onClick={() => handleOnClick(tracesList[virtualRow.index]?.id)}
                        >
                          <img
                            className={`${
                              activeTraceIds.includes(tracesList[virtualRow.index]?.id)
                                ? "trace-accordion-arrow-up"
                                : "trace-accordion-arrow-down"
                            }`}
                            src={up}
                          />
                        </div>
                      </div>

                      {activeTraceIds.includes(tracesList[virtualRow.index]?.id) && (
                        <div>
                          <TraceDetails
                            trace={tracesList[virtualRow.index]}
                            showTraceProperties={true}
                            terminalId={traceContext?.terminalId}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center trace-error">
              <img className="trace-error-icon" src={infoIcon} />
              {`${i18nInstance.t("TTM.followup.error.noDataAvailable")}`}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(TracesListPanel);
