import React, { useEffect, useRef, useState } from "react";
import "./TracesContainer.scss";
import moment from "moment";
import isEqual from "lodash/isEqual";
import { observer } from "mobx-react-lite";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import TraceService from "../../../../../services/Trace.service";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { ITimelineSegmentData, ITimeRange, ITrace } from "../../../../../models/TraceModel";
import { useEventSource } from "../../../../../hooks/useEventSource/useEventSource";
import {
  getEndTimeFromDate,
  getIdsWithinTimeRange,
  getStartTimeFromDate,
  sendMonitoringLogs,
} from "../../../../../common/utils";
import { DATE_DISPLAY_FORMAT, PATH, TOAST_STYLE } from "../../../../../common/constants";
import ToastWrapper, {
  IToast,
  defaultToastObj,
} from "../../../molecules/ToastWrapper/ToastWrapper";
import { useTraceStore } from "../../../../../contexts/traces.context";

const TracesContainer = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const traceStore = useTraceStore();
  const traceService = new TraceService();

  const [sourceURL, setSourceURL] = useState<string>("");
  const [terminalId, setTerminalId] = useState<string>("");
  const [queryDate, setQueryDate] = useState<string>("");

  const masterTracesList = useRef<ITrace[]>([]); //Master traces data
  const [selectedTrace, setSelectedTrace] = useState<ITrace | null>(null);
  const [highlightedTraceIds, setHighlightedTraceIds] = useState<string[]>([]);
  const [selectedTask, setSelectedTask] = useState<ITimelineSegmentData | null>(null);

  const [resetTraces, setResetTraces] = useState<boolean>(false);
  const [toastObj, setToastObj] = useState<IToast>(defaultToastObj);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  const [timeRange, setTimeRange] = useState<ITimeRange | null>(null);

  const [source, data, isCompleted, error] = useEventSource({
    src: { url: sourceURL },
  });

  useEffect(() => {
    if (data) {
      masterTracesList.current = [...masterTracesList.current, data];
    }
  }, [data]);

  const onSelectedTrace = (trace: ITrace) => {
    setSelectedTrace(trace);
  };
  const onSelectedTask = (task: ITimelineSegmentData) => {
    setSelectedTask(task);
  };
  const onHighlightedTraces = (traceIds: string[]) => {
    setHighlightedTraceIds(traceIds);
  };

  const shouldUpdateTraceFilter = () => {
    const { selectedDate, terminalId } = traceStore?.traceFilter || {};
    return (
      !isEqual(selectedDate, moment(params.date, DATE_DISPLAY_FORMAT, true)) ||
      !isEqual(terminalId, params.unitId)
    );
  };

  const shouldResetTraceDetails = (): boolean => {
    const traceTypes = traceStore?.traceFilter?.traceTypes || [];
    return params?.unitId !== terminalId || params?.date !== queryDate || traceTypes?.length >= 0;
  };

  /**
   * Function to set trace filter's time range based on the click or zoom event on the timeline.
   * @param startTime - Start time of the timeline when a user clicks or zooms in.
   * @param endTime - End time of the timeline of the timeline when a user clicks or zooms in.
   * @param data - Data from the timeline when user clicks on a particular segment.
   */
  const onTimelineChange = (startTime: number, endTime: number, data?: any) => {
    try {
      const tracesInRange = getIdsWithinTimeRange(masterTracesList.current, startTime, endTime);
      if (data) {
        onHighlightedTraces(tracesInRange);
        sendMonitoringLogs("HISTORY_TIMELINE_SEGMENT_CLICK");
      } else {
        setTimeRange({ startTime, endTime });
        sendMonitoringLogs("HISTORY_TIMELINE_ZOOM");
        onHighlightedTraces([]);
      }
    } catch (error) {
      console.log("onTimelineChange ~ error:", error);
    }
  };

  const onCleanUp = () => {
    masterTracesList.current = [];
    setResetTraces(false);
    setSourceURL("");
    setSelectedTrace(null);
    setHighlightedTraceIds([]);
    setSelectedTask(null);
    setTimeRange(null);
    traceStore?.clearSelectedViewJsonConfig();
  };

  useEffect(() => {
    error &&
      setToastObj({
        showToast: true,
        message: i18nInstance.t("TTM.followup.generic.error"),
        style: TOAST_STYLE.ERROR,
      });
  }, [error]);

  /**
   * UseEffect to abort/close the stream when user changes the unit while existing request in progress.
   */
  useEffect(() => {
    if ((params?.unitId !== terminalId || params?.date !== queryDate) && !isCompleted) {
      source?.close();
    }
  }, [params?.unitId, params?.date, isCompleted]);

  useEffect(() => {
    setIsDataLoaded(isCompleted);
  }, [isCompleted]);

  /**
   * UseEffect to define start and end time from the params.
   * If date is invalid, user will be navigated to /history path.
   * If unitId or date is changed, reset will be set to true.
   */
  useEffect(() => {
    try {
      if (
        params?.unitId &&
        params?.date &&
        moment(params?.date, DATE_DISPLAY_FORMAT, true)?.isValid() &&
        moment(params?.date, DATE_DISPLAY_FORMAT, true)?.isSameOrBefore(moment())
      ) {
        const traceTypes = traceStore?.traceFilter?.traceTypes;
        setTerminalId(params?.unitId);
        setQueryDate(params?.date);
        setIsDataLoaded(false);
        if (shouldResetTraceDetails()) {
          setResetTraces(true);
          setSelectedTrace(null);
          traceStore?.setTraceDetails(null);
        }
        if (shouldUpdateTraceFilter()) {
          traceStore?.setTraceFilter?.({
            ...traceStore.traceFilter,
            selectedDate: moment(params.date, DATE_DISPLAY_FORMAT, true),
            terminalId: params.unitId,
          });
        }
        const startTime = getStartTimeFromDate(params.date);
        const endTime = getEndTimeFromDate(params.date);
        setSourceURL(
          traceService.getTraces(
            params.unitId,
            startTime,
            endTime,
            traceTypes?.map((i) => i.value)?.join(),
          ),
        );
        setToastObj({
          showToast: true,
          message: i18nInstance.t("TTM.followup.traces.loading"),
          style: TOAST_STYLE.PRIMARY,
          delay: 850,
        });
      } else {
        navigate(PATH.TRACES);
      }
    } catch (error) {
      console.log("tracesContainer ~ error:", error);
    }
    return () => {
      onCleanUp();
    };
  }, [location?.pathname, traceStore?.traceFilter?.traceTypes]);

  return (
    <>
      <Outlet
        context={{
          error,
          queryDate,
          terminalId,
          trace: data,
          resetTraces,
          selectedTask,
          selectedTrace,
          highlightedTraceIds,
          isDataLoaded,
          timeRange,
          traceFilter: traceStore?.traceFilter,
          masterTracesList: masterTracesList.current,
          handleShowToast: setToastObj,
          handleResetTraces: onCleanUp,
          handleSelectedTrace: onSelectedTrace,
          handleHighlightedTraces: onHighlightedTraces,
          handleSelectedTask: onSelectedTask,
          handleOnTimelineChange: onTimelineChange,
        }}
      />
      <div className="traces-toast">
        <ToastWrapper
          toastProps={toastObj}
          onClose={() => setToastObj({ ...toastObj, showToast: false })}
        />
      </div>
    </>
  );
};

export default observer(TracesContainer);
