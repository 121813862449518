import React from "react";
import "./TraceVehiclesPanel.scss";

import i18nInstance from "@ttl/shared-react-library/src/i18n";
import TraceVehiclesList from "../TraceVehiclesList/TraceVehiclesList";

const TraceVehiclesPanel = () => {
  return (
    <div className="block-container">
      <div className="trace-vehicles-container">
        <span className="trace-vehicles-title">
          {i18nInstance.t("TTM.followup.traces.tracesAudit")}
        </span>
        <div className="vehicle-list-container">
          <TraceVehiclesList />
        </div>
      </div>
    </div>
  );
};

export default TraceVehiclesPanel;
