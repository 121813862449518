import React from "react";
import { ITimelineSegmentData, ITrace, ITraceType } from "../models/TraceModel";
import { coordinateToDegree } from "./utils";
import { IActivityType } from "../models/VehicleModel";
import {
  ACTIVITY_CATEGORY,
  ACTIVITY_COLORS,
  COLOR_VALUES,
  STATE,
  DRIVING_TIMES_CATEGORY,
  DRIVING_TYPES_COLORS,
  HISTORY_VIEWS,
  DRIVING_TYPES,
} from "./constants";
import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
} from "@trimblemaps/trimblemaps-js/geojson";
import { Dictionary, groupBy } from "lodash";
import { ITimelineSegment } from "@ttl/shared-react-library/src/packages/timeline/models/TimelineModel";
import { TraceStore } from "../stores/traceStore/trace.store";
import i18nInstance from "@ttl/shared-react-library/src/i18n";

export const emptyFeatureCollection: FeatureCollection<Geometry, GeoJsonProperties> = {
  type: "FeatureCollection",
  features: [],
};

export const updateGeoJSON = (features: Feature[], updateProperties?: any) => {
  return {
    type: "FeatureCollection",
    features: features
      .map((feature: Feature) => {
        const updatedProperties = updateProperties(feature);
        if (!updatedProperties) {
          return null;
        }
        return {
          ...feature,
          properties: {
            ...feature.properties,
            ...updatedProperties,
          },
        };
      })
      .filter(Boolean),
  };
};

export const updateFeaturesWithWeightage = (features: Feature[], jsonData: any) => {
  const updatedGeoJSON = updateGeoJSON(features, (feature: Feature) => {
    if (feature && feature.properties && feature.properties.trace) {
      const weightage = jsonData?.[feature.properties.trace.type]?.weightage ?? 0;
      return { weightage };
    }
  });
  return updatedGeoJSON as FeatureCollection<Geometry, GeoJsonProperties>;
};

export const getCoordinates = (coordinate: { lon: number; lat: number }) => {
  const lon = coordinateToDegree(coordinate.lon);
  const lat = coordinateToDegree(coordinate.lat);
  return { lon, lat };
};

export const getTraceFeature = (trace: ITrace) => {
  try {
    if (trace?.coordinate && trace.coordinate?.lon && trace.coordinate.lat) {
      const { lon, lat } = getCoordinates(trace.coordinate);
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [lon, lat],
        },
        properties: {
          id: trace?.id?.toString(),
          trace: trace,
          icon: "trace-marker",
          heading: trace?.heading,
          coordinates: [lon, lat],
          timestamp: trace?.timestamp,
        },
      } as Feature;
    }
  } catch (error) {
    console.log("TraceMapService ~ getTraceFeature ~ error:", error);
  }
};

export const getFeaturesByWeightage = (
  features: Feature[],
  jsonData: any,
  comparison: (weightage: number) => boolean,
) => {
  return features.filter((feature) => {
    const traceTypeId = feature?.properties?.trace?.type;
    const weightage = jsonData[traceTypeId]?.weightage;
    return comparison(weightage);
  });
};

export const getTaskFeature = (task: ITimelineSegmentData) => {
  try {
    if (task?.coordinate && task.coordinate?.lon && task.coordinate.lat) {
      const { lon, lat } = getCoordinates(task.coordinate);
      const taskStatus =
        task?.status == 5 || task?.status == 7
          ? STATE[task?.status].toString().toLowerCase()
          : "other";
      const taskOrder = task?.order;
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [lon, lat],
        },
        properties: {
          id: task?.id,
          task: task,
          coordinates: [lon, lat],
          custom_icon: `${taskStatus}-${taskOrder}`,
          iconName: taskStatus,
          order: taskOrder,
        },
      } as Feature;
    }
  } catch (error) {
    console.log("TraceMapService ~ getTaskFeature ~ error:", error);
  }
};

export const processSegments = (segments: ITimelineSegment[]) => {
  const segmentsWithTrip = segments.filter((segment: ITimelineSegmentData) => segment?.trip?.id);

  const segmentsWithoutTrip = segments
    .filter((segment: ITimelineSegmentData) => !segment?.trip?.id)
    .map((segment: ITimelineSegmentData, index: number) => ({
      ...segment,
      order: `${index + 1}`,
    }));

  const groupedSegments = groupBy(segmentsWithTrip, (segment: ITimelineSegmentData) => {
    const tripId = segment?.trip?.id;
    return tripId || "";
  }) as Dictionary<ITimelineSegmentData[]>;

  return {
    segmentsWithoutTrip,
    groupedSegments,
  };
};

export const createImageFromSvg = (svgText: string, resolve: any) => {
  const blob = new Blob([svgText], { type: "image/svg+xml" });
  const url = URL.createObjectURL(blob);
  const img = new Image();
  img.onload = () => resolve(img);
  img.src = url;
};

export const modifyTraceSvgIcon = (svgText: string, color: string, resolve: any) => {
  const modifiedSvg = svgText
    .replace(/fill="[current]*"/g, `fill="${color}"`)
    .replace(/stroke="[current]*"/g, `stroke="${color}"`);
  createImageFromSvg(modifiedSvg, resolve);
};

export const modifyTaskSvgIcon = (svgText: string, order: number, resolve: any) => {
  const modifiedSvg = svgText.replace(
    /<tspan[^>]*>(.*?)<\/tspan>/,
    `<tspan x="10" y="14.5">${order}</tspan>`,
  );
  createImageFromSvg(modifiedSvg, resolve);
};

export const modifyCustomSvgIcon = (svgText: string, color: string) => {
  const modifiedSvg = svgText.replace(
    /(<path[^>]*?)fill="#fff"|fill="current"|stroke="current"/g,
    (match, pathPrefix) => {
      if (pathPrefix) return `${pathPrefix}fill="${color}"`;
      if (match.startsWith('fill="')) return `fill="#fff"`;
      if (match.startsWith('stroke="')) return `stroke="#fff"`;
      return match;
    },
  );
  const blob = new Blob([modifiedSvg], { type: "image/svg+xml" });
  return URL.createObjectURL(blob);
};

export const isCustomTraceView = (traceType: number, traceStore: TraceStore | null) => {
  if (traceStore?.selectedView !== HISTORY_VIEWS.DEFAULT) {
    return (
      traceStore?.selectedViewJsonConfig.get(traceStore?.selectedView)?.[traceType]?.weightage == 3
    );
  } else {
    return false;
  }
};

/**
 * Function to get trace name, icon and color based on the selected view
 * @param selectedView: user selected view
 * @param trace: trace object
 * @param activityTypes: activity types from the app store
 * @param traceTypes: trace types from the trace store
 * @returns traceTypeName, customIcon, color values
 */
export const getTraceNameIconColor = (
  selectedView?: string,
  trace?: ITrace,
  activityTypes?: IActivityType[],
  traceTypes?: ITraceType[],
) => {
  let customIcon = "";
  let color = COLOR_VALUES.GRAY_450;
  let traceTypeName = "";

  const activityType = activityTypes?.find((a) => a.id === trace?.eventTypeId);
  const activityTypeName = activityType?.name;
  const traceType = trace?.type;
  const traceTypeNameFromTypes = traceTypes?.find((t) => t.traceType === traceType)?.name;

  switch (selectedView) {
    case HISTORY_VIEWS.ACTIVITY:
      customIcon =
        ACTIVITY_CATEGORY[activityType?.activityCategory as keyof typeof ACTIVITY_CATEGORY] || "";
      color = ACTIVITY_COLORS[activityType?.icon as keyof typeof ACTIVITY_COLORS] || color;
      traceTypeName = activityTypeName || customIcon;
      break;

    case HISTORY_VIEWS.DRIVING_TIMES:
      if (traceType === 82) {
        customIcon = trace?.eventTypeId?.toLowerCase() || "";
        color =
          DRIVING_TYPES_COLORS[trace?.eventTypeId as keyof typeof DRIVING_TYPES_COLORS] || color;
        traceTypeName = i18nInstance.t(`TTM.followup.tachoStatus.${customIcon}`);
      } else {
        customIcon =
          DRIVING_TIMES_CATEGORY[traceType as keyof typeof DRIVING_TIMES_CATEGORY]?.icon || "";
        color = traceType === 44 ? COLOR_VALUES.BLUE_300 : COLOR_VALUES.YELLOW_100;
        traceTypeName = traceTypeNameFromTypes || customIcon;
      }
      break;

    case HISTORY_VIEWS.FUEL:
      customIcon =
        traceType && [68, 69].includes(traceType)
          ? "fuel"
          : ACTIVITY_CATEGORY[activityType?.activityCategory as keyof typeof ACTIVITY_CATEGORY] ||
            "";
      color =
        traceType === 68
          ? COLOR_VALUES.GREEN_100
          : traceType === 69
          ? COLOR_VALUES.RED_200
          : ACTIVITY_COLORS[activityType?.icon as keyof typeof ACTIVITY_COLORS] || color;
      traceTypeName = activityTypeName || traceTypeNameFromTypes || customIcon;
      break;

    default:
      traceTypeName = customIcon;
  }
  return { traceTypeName, customIcon, color };
};

/**
 * Function to get updated geoJSON based on the selected view
 * @param features: height weightage features
 * @param selectedView: user selected view
 * @param activityTypes: activity types from the app store
 * @param traceTypes: trace types from the trace store
 * @param configJSON: config JSON for the selected view
 * @returns updated geoJSON
 */
export const getUpdatedGeoJSON = (
  features: Feature[],
  selectedView: string,
  activityTypes: IActivityType[],
  traceTypes: ITraceType[],
  configJSON: any,
) => {
  return updateGeoJSON(features, ({ properties }: Feature) => {
    const trace = properties?.trace;
    if (!trace) return;

    let weightage = configJSON?.[trace.type]?.weightage ?? 0;
    const activity = activityTypes.find((a) => a.id === trace.eventTypeId);

    if (selectedView === HISTORY_VIEWS.ACTIVITY) {
      weightage = activity?.code === "DR" ? 1 : weightage;
    } else if (
      selectedView === HISTORY_VIEWS.DRIVING_TIMES &&
      trace.type === 82 &&
      trace.eventTypeId.toLowerCase() === DRIVING_TYPES.DRIVING.toLowerCase()
    ) {
      return null;
    } else if (selectedView === HISTORY_VIEWS.FUEL) {
      if ([68, 69].includes(trace.type)) {
        //Fuel trace types increase or decrease
        weightage = configJSON?.[trace.type]?.weightage ?? 0;
      } else if ([13, 16].includes(trace.type) && activity?.activityCategory !== 8) {
        //Fuel activity category is 8
        return null;
      }
    }
    if (weightage < 3) return null;
    const { traceTypeName, customIcon, color } = getTraceNameIconColor(
      selectedView,
      trace,
      activityTypes,
      traceTypes,
    );

    return {
      weightage,
      trace_type_name: traceTypeName,
      custom_icon: customIcon,
      activity_icon: color,
      icon_name: `${customIcon}_${color}`,
    };
  }) as FeatureCollection<Geometry, GeoJsonProperties>;
};
