import React, { useEffect, useRef, useState } from "react";
import "./DetailsView.scss";
import { observer } from "mobx-react-lite";
import { getCrewDriver, sendMonitoringLogs } from "../../../common/utils";
import { DETAILS_TAB, MENUS, PATH, PERMISSIONS } from "../../../common/constants";
import TitleView from "../../molecules/TitleView/TitleView";
import { IVehicleData } from "../../../models/VehicleModel";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import VehicleDetails from "../VehicleDetails/VehicleDetails";
import { ISearchResult } from "../SearchView/SearchViewModel";
import useMobileDetect from "../../../hooks/useMobileDetect/useMobileDetect";
import withAuth from "@ttl/shared-react-library/src/auth/WithAuth";
import { IRoute, ITask } from "../../../models/VehicleDetailsModel";
import DrivingTimeDetails from "../DrivingTimeDetails/DrivingTimeDetails";
import Button from "@trimbleinc/modus-react-bootstrap/esm/Button";
import { useAppStore } from "../../../contexts/app.context";
import DispatchMessageModal from "../DispatchMessageModal/DispatchMessageModal";
import MessageService from "../../../services/Message.service";
import { useSwipe } from "../../../hooks/useSwipe/useSwipe";
import { useParams } from "react-router-dom";
import MessageConversationWrapper from "../MessageConversationWrapper/MessageConversationWrapper";

export interface IDetailsView {
  onClose?: () => void;
  selectedVehicle: IVehicleData;
  selectedRoute?: IRoute | null;
  selectedTask?: ITask | null;
  searchedLocation?: ISearchResult;
  lastUpdatedTime?: number;
  setSelectedRoute?: (route: IRoute | null | undefined) => void;
}

const DetailsView = observer((props: IDetailsView) => {
  const appStore = useAppStore();
  const isMobile = useMobileDetect();
  const params = useParams();
  const currentVehicleRef = useRef("");
  const detailsContainerRef = useRef<HTMLDivElement>(null);
  const messageService = new MessageService();
  const [unread, setUnread] = useState<number>(0);
  const isDispatchView = location?.pathname.includes(PATH.DISPATCH);
  const [activeTab, setActiveTab] = useState<DETAILS_TAB>(DETAILS_TAB.VEHICLE);
  const [lastUpdatedTime, setLastUpdatedTime] = useState<number>(0);

  const showMessageTab =
    props?.selectedVehicle?.vehicle?.messagingCapable &&
    appStore?.permissions.includes(PERMISSIONS.MESSAGING) &&
    !isDispatchView;

  const showDrivingTimeDetails =
    props?.selectedVehicle?.crew?.length > 0 &&
    getCrewDriver?.(props?.selectedVehicle?.crew)?.id !== "";

  const showHistoryIcon = appStore?.authorizedMenus?.includes(MENUS.HISTORY);
  const showMessageIcon =
    props?.selectedVehicle?.vehicle?.messagingCapable &&
    appStore?.authorizedMenus?.includes(MENUS.MESSAGE_CENTER);

  const handleOnSwipeLeft = () => {
    setActiveTab((prevTab) => {
      switch (prevTab) {
        case DETAILS_TAB.VEHICLE:
          return showDrivingTimeDetails
            ? DETAILS_TAB.DRIVING
            : showMessageTab
            ? DETAILS_TAB.MESSAGE
            : prevTab;
        case DETAILS_TAB.DRIVING:
          return showMessageTab ? DETAILS_TAB.MESSAGE : prevTab;
        default:
          return prevTab;
      }
    });
  };

  const handleOnSwipeRight = () => {
    setActiveTab((prevTab) => {
      switch (prevTab) {
        case DETAILS_TAB.MESSAGE:
          return showDrivingTimeDetails ? DETAILS_TAB.DRIVING : DETAILS_TAB.VEHICLE;
        case DETAILS_TAB.DRIVING:
          return DETAILS_TAB.VEHICLE;
        default:
          return prevTab;
      }
    });
  };

  useSwipe({
    elementRef: detailsContainerRef,
    isEnabled: isMobile && (showDrivingTimeDetails || showMessageTab),
    onSwipeLeft: handleOnSwipeLeft,
    onSwipeRight: handleOnSwipeRight,
  });

  const toggleActiveTab = () => {
    if (activeTab === DETAILS_TAB.DRIVING) setActiveTab(DETAILS_TAB.DRIVING);
    if (activeTab === DETAILS_TAB.VEHICLE) setActiveTab(DETAILS_TAB.VEHICLE);
    if (activeTab === DETAILS_TAB.MESSAGE) setActiveTab(DETAILS_TAB.MESSAGE);
  };
  const getUnreadCount = (id: string) => {
    if (id && props?.selectedVehicle?.vehicle?.messagingCapable) {
      messageService
        .getUnreadMessagesCount(id)
        .then((response) => {
          if (response?.data?.[0]) {
            setUnread(response.data[0].unreadCount);
          }
        })
        .catch((e) => {
          console.log("DetailsView Error", e);
        });
      setLastUpdatedTime(new Date().getTime());
    }
  };

  //---------------- LIFE CYCLE HOOKS ----------------//
  useEffect(() => {
    const selectedVehicleId = currentVehicleRef.current?.toString?.() || "";
    if (
      selectedVehicleId &&
      appStore?.msglastUpdatedTime &&
      appStore?.vehiclesNotificationsList.includes(selectedVehicleId)
    ) {
      getUnreadCount(selectedVehicleId);
    }
  }, [appStore?.msglastUpdatedTime]);
  //* To show vehicle details tab when user selects a vehicle from the list.
  useEffect(() => {
    setUnread(0);
    if (currentVehicleRef.current !== props?.selectedVehicle?.id) {
      setActiveTab(DETAILS_TAB.VEHICLE);
      currentVehicleRef.current = props?.selectedVehicle?.id;
      getUnreadCount(props.selectedVehicle.id);
    } else {
      toggleActiveTab();
    }
  }, [props?.selectedVehicle]);

  //* To retain the user selected tab while auto-refreshing.
  useEffect(() => {
    if (lastUpdatedTime && props?.lastUpdatedTime && props.lastUpdatedTime !== lastUpdatedTime) {
      toggleActiveTab();
      getUnreadCount(props.selectedVehicle.id);
    }
  }, [props?.lastUpdatedTime]);

  return (
    <div className="details-view">
      {props?.selectedVehicle && props?.selectedVehicle.vehicle && (
        <div className="vehicle-title-block">
          <TitleView
            id={props.selectedVehicle.id}
            isMobile={isMobile}
            showCloseIcon
            showHistoryIcon={showHistoryIcon}
            showMessageIcon={showMessageIcon}
            onClose={props?.onClose}
            type={props.selectedVehicle.vehicle.type}
            title={props.selectedVehicle.vehicle.name}
            phone={props.selectedVehicle.vehicle.phone}
            status={props.selectedVehicle.vehicle.status}
            unread={unread}
          />
        </div>
      )}
      <div className={`tab-container ${isMobile && "tab-container-mobile"}`}>
        {/* Tab switch block */}
        <ul className="nav nav-tabs">
          <li
            className="nav-item"
            onClick={() => {
              setActiveTab(DETAILS_TAB.VEHICLE);
              sendMonitoringLogs("VEHICLE_DETAILS_TAB_SWITCH");
            }}
          >
            <span className={`nav-link ${activeTab === DETAILS_TAB.VEHICLE && "active"}`}>
              {i18nInstance.t("TTM.followup.vehicle.details")}
            </span>
          </li>
          {showDrivingTimeDetails && (
            <li
              className="nav-item"
              onClick={() => {
                setActiveTab(DETAILS_TAB.DRIVING);
                sendMonitoringLogs("VEHICLE_DRIVINGTIMES_TAB_SWITCH");
              }}
            >
              <span className={`nav-link ${activeTab === DETAILS_TAB.DRIVING && "active"}`}>
                {i18nInstance.t("TTM.followup.drivingTimes.details")}
              </span>
            </li>
          )}
          {showMessageTab && (
            <li
              className="nav-item"
              onClick={() => {
                setActiveTab(DETAILS_TAB.MESSAGE);
                sendMonitoringLogs("VEHICLE_MESSAGE_TAB_SWITCH");
              }}
            >
              <span className={`nav-link ${activeTab === DETAILS_TAB.MESSAGE && "active"}`}>
                {i18nInstance.t("TTM.followup.message")}
              </span>
            </li>
          )}
        </ul>
      </div>

      <div
        className={`col details-panel ${isMobile ? "details-panel-mobile" : ""} ${
          isDispatchView ? "details-panel-dispatch" : ""
        } p-0 pt-2`}
        ref={detailsContainerRef}
      >
        {activeTab === DETAILS_TAB.VEHICLE && (
          <VehicleDetails
            selectedTask={props?.selectedTask}
            selectedRoute={props?.selectedRoute}
            lastUpdatedTime={props?.lastUpdatedTime}
            selectedVehicle={props?.selectedVehicle}
            searchedLocation={props?.searchedLocation}
            setSelectedRoute={props?.setSelectedRoute}
            actionName={`${isDispatchView ? "DISPATCH_DETAILS" : "VEHICLE_DETAILS"}`}
          />
        )}
        {activeTab === DETAILS_TAB.DRIVING && (
          <DrivingTimeDetails
            lastUpdatedTime={props?.lastUpdatedTime}
            driver={getCrewDriver(props?.selectedVehicle?.crew)}
          />
        )}
        {activeTab === DETAILS_TAB.MESSAGE && (
          <MessageConversationWrapper
            unitId={params?.vehicleId}
            showVehicleHeader={false}
            driverName={getCrewDriver(props?.selectedVehicle?.crew)?.name}
          />
        )}
      </div>
      {isDispatchView && (
        <div className="details-actionBtn">
          <Button
            id="messageBtn"
            className="cursor-pointer"
            disabled={!props?.selectedVehicle?.vehicle?.messagingCapable}
            variant="primary"
            title={
              props?.selectedVehicle?.vehicle?.messagingCapable
                ? i18nInstance.t("TTM.followup.dispatch.sendMessage")
                : i18nInstance.t("TTM.followup.dispatch.notCapableToSendMessage")
            }
            onClick={() => appStore?.setShowModal(true)}
          >{`${i18nInstance.t("TTM.followup.message")}`}</Button>
        </div>
      )}
      {appStore?.showModal && (
        <DispatchMessageModal
          location={props?.searchedLocation}
          terminalId={props?.selectedVehicle?.id}
        />
      )}
    </div>
  );
});

export default withAuth(
  React.memo(
    DetailsView,
    (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps),
  ),
);
