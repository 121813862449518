import { action, makeObservable, observable } from "mobx";
import { IListData } from "../../components/molecules/AutoComplete/AutoComplete";

export class MessageStore {
  vehicleList: IListData[] = [];
  showModal = false;
  constructor() {
    makeObservable(this, {
      vehicleList: observable,
      showModal: observable,
      setVehicleList: action,
      setShowModal: action,
    });
  }
  setVehicleList(list: IListData[]) {
    this.vehicleList = list;
  }
  setShowModal(flag: boolean) {
    this.showModal = flag;
  }
}
