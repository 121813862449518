import React, { useEffect, useRef, useState } from "react";
import "./TraceTypesSearch.scss";
import MultiSelect, { IMultiSelect } from "../../../../molecules/MultiSelect/MultiSelect";
import { useTraceStore } from "../../../../../contexts/traces.context";
import { observer } from "mobx-react-lite";
import useMobileDetect from "../../../../../hooks/useMobileDetect/useMobileDetect";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import close from "../../../../../assets/images/close.svg";
import { sendMonitoringLogs } from "../../../../../common/utils";
import { isEqual } from "lodash";
import { HISTORY_VIEWS } from "../../../../../common/constants";

interface ITraceTypeSearch {
  onClose?: (val?: any) => void;
}

const TraceTypeSearch = (props: ITraceTypeSearch) => {
  const traceStore = useTraceStore();
  const isMobile = useMobileDetect();
  const [traceTypeOptions, setTraceTypeOptions] = useState<IMultiSelect[]>([]);
  const [filteredTraceTypes, setFilteredTraceTypes] = useState<IMultiSelect[]>([]);

  const traceTypes = useRef(traceStore?.mappedTraces || []);

  const handleOnApply = () => {
    try {
      if (
        filteredTraceTypes.length > 0 &&
        !isEqual(filteredTraceTypes, traceStore?.traceFilter?.traceTypes)
      ) {
        traceStore?.setTraceFilter({
          ...traceStore.traceFilter,
          traceTypes: filteredTraceTypes,
        });
      }
      isMobile && props.onClose?.();
      sendMonitoringLogs(
        "HISTORY_FILTER_TRACE_TYPES",
        filteredTraceTypes.map((trace) => trace?.label)?.join(","),
      );
    } catch (error) {
      console.log("traceTypeSearch ~ handleOnApply ~ error:", error);
    }
  };

  // In mobile, we don't want to reset the trace types on clear.
  // We clear, only when user deselects the trace types and closes the filter panel.
  const handleOnClear = () => {
    sendMonitoringLogs("HISTORY_FILTER_TRACE_TYPES_CLEAR");
    setFilteredTraceTypes([]);
    !isMobile &&
      traceStore?.setTraceFilter({
        ...traceStore.traceFilter,
        traceTypes: [],
      });
  };

  useEffect(() => {
    if (traceStore?.traceFilter?.traceTypes && traceStore?.traceFilter?.traceTypes?.length > 0) {
      setFilteredTraceTypes(traceStore?.traceFilter?.traceTypes);
    }
  }, []);

  /**
   * Function to fetch the trace types based on the selected view.
   * @param jsonConfig - selected view JSON config from the History select.
   */
  const setTraceOptionsFromConfig = (config: any) => {
    try {
      if (!config) return;
      const viewTraceTypes = traceStore?.mappedTraces?.filter((traceType) =>
        Object.keys(config).includes(traceType?.id?.toString() ?? ""),
      );
      setTraceTypeOptions(viewTraceTypes || []);

      if (viewTraceTypes && filteredTraceTypes?.length) {
        const matchingFilteredTraces = viewTraceTypes.filter((traceType) =>
          filteredTraceTypes.some((trace) => trace.value === traceType.value),
        );
        setFilteredTraceTypes(matchingFilteredTraces);
        if (!isEqual(matchingFilteredTraces, filteredTraceTypes)) {
          traceStore?.setTraceFilter({
            ...traceStore.traceFilter,
            traceTypes: matchingFilteredTraces,
          });
        }
      }
    } catch (error) {
      console.error("Error in setTraceOptionsFromConfig:", error);
    }
  };

  const updateTraceOptions = (jsonConfig: any) => {
    try {
      const { selectedView, mappedTraces } = traceStore || {};
      traceTypes.current = mappedTraces || [];

      const updatedTraceFilter = traceTypes.current.filter((traceType) =>
        filteredTraceTypes.some((filteredTraceType) => filteredTraceType.id === traceType.id),
      );

      setFilteredTraceTypes(updatedTraceFilter);
      if (selectedView !== HISTORY_VIEWS.DEFAULT) {
        setTraceOptionsFromConfig(jsonConfig);
      } else {
        setTraceTypeOptions(traceTypes.current);
      }
    } catch (error) {
      console.error("Error in updateTraceOptions:", error);
    }
  };

  useEffect(() => {
    if (traceStore?.selectedView) {
      const jsonConfig = traceStore?.selectedViewJsonConfig.get(traceStore?.selectedView);
      updateTraceOptions(jsonConfig);
    }
  }, [traceStore?.traceTypes, traceStore?.selectedViewJsonConfig.get(traceStore?.selectedView)]);

  return (
    <div className={`trace-types-multiselect${isMobile ? "-mob" : ""}`}>
      {isMobile && (
        <div className="trace-types-header">
          <span>{i18nInstance.t("TTM.followup.traces.tracesTypeList")}</span>
          <img
            src={close}
            className="close-icon"
            alt={i18nInstance.t("TTM.followup.close")}
            onClick={() => props?.onClose?.(filteredTraceTypes)}
          />
        </div>
      )}
      {traceTypes && traceTypes.current.length > 0 && (
        <div className={`${isMobile && "trace-types-mob"}`}>
          <MultiSelect
            label={""}
            enableSearch
            showApplyButton
            options={traceTypeOptions}
            defaultIsOpen={isMobile}
            value={filteredTraceTypes}
            placeholder={i18nInstance.t("TTM.followup.traces.filter.tracesTypes")}
            onApply={handleOnApply}
            onClear={handleOnClear}
            onChange={setFilteredTraceTypes}
          />
        </div>
      )}
    </div>
  );
};

export default observer(TraceTypeSearch);
