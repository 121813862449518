import moment from "moment";
import { getAppConfig } from "../common/utils";
import { IFilterParams, IMessage } from "../models/VehicleModel";
import { AppService, FetchService } from "./app.services";
import { getCategoryIconMappingURL, getFollowupURL } from "../common/serviceUtils";
class VehicleService {
  appConfig = getAppConfig();
  getList(fileName = "vehicles") {
    if (this.appConfig.loadFromAPI) {
      return AppService.axios.get(getFollowupURL("vehicle/list"));
    } else {
      const dataURL = this.appConfig.vehiclesDataURL?.replace("{{fileName}}", fileName);
      const currentTimeStamp = moment().utc().valueOf();
      return AppService.axios.get(`${dataURL}?v=${currentTimeStamp}`);
    }
  }
  getDetails(id: string, controller?: AbortController) {
    return AppService.axios.get(getFollowupURL(`vehicle/detail?terminalId=${id}`), {
      signal: controller?.signal,
    });
  }
  getReport(id: string) {
    return AppService.axios.get(getFollowupURL(`v1/vehicles/${id}/report`));
  }
  getFilterUrl = (filterParams: IFilterParams) => {
    return Object.entries(filterParams)
      .map((paramVals) => paramVals.join(":"))
      .join(" AND ");
  };
  getVehicleGroups() {
    return AppService.axios.get(getFollowupURL("v1/vehiclegroups"));
  }
  getFilteredList(param: IFilterParams) {
    return AppService.axios.get(getFollowupURL(`v1/vehicles?search=${this.getFilterUrl(param)}`));
  }
  sendMessage(terminalId: string, data?: IMessage) {
    return AppService.axios.post(getFollowupURL(`vehicle/message?terminalId=${terminalId}`), data);
  }
  getDrivingTimes(driverId: string, controller?: AbortController) {
    return AppService.axios.get(getFollowupURL(`v1/driver/${driverId}/drivingtimes`), {
      signal: controller?.signal,
    });
  }
  getTruckProfile(terminalId: string, controller?: AbortController) {
    return AppService.axios.get(getFollowupURL(`v1/vehicle/${terminalId}/profile`), {
      signal: controller?.signal,
    });
  }
  getActualRouteTrails(terminalId: string, taskId: string[], controller?: AbortController) {
    return AppService.axios.get(
      getFollowupURL(`v1/vehicle/${terminalId}/tasks/${taskId}/actualroute`),
      {
        signal: controller?.signal,
      },
    );
  }
  getDrivingTimeDetails(driverId: string) {
    return AppService.axios.get(getFollowupURL(`v1/driver/${driverId}/detaileddrivingtimes`));
  }
  getCategoryMappingJSON() {
    return FetchService.axios.get(getCategoryIconMappingURL());
  }
}
export default VehicleService;
