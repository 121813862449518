import { action, computed, makeObservable, observable } from "mobx";
import { IMultiSelect } from "../../components/molecules/MultiSelect/MultiSelect";
import { IActivityType, IVehicleData } from "../../models/VehicleModel";
import { IMenuItem } from "../../common/menuList";
import { filterMenusByPermission } from "../../common/sideMenuUtils";
import { ICategoryIconMapping } from "../../common/utils";
import { IMessageType } from "../../models/MessageModel";
export class AppStore {
  permissions: string[] = [];
  vehicleGroups: IMultiSelect[] = [];
  activityTypes: IActivityType[] = [];
  vehiclesList: IVehicleData[] = [];
  vehiclesNotificationsList: string[] = [];
  showModal = false;
  msgUnreadCount = 0;
  msglastUpdatedTime = 0;
  userLanguage = "en";
  categoryIconMapping: ICategoryIconMapping[] = [];
  messageTypes: IMessageType[] = [];
  maxFileSize = 10000000;
  constructor() {
    makeObservable(this, {
      vehicleGroups: observable,
      activityTypes: observable,
      vehiclesList: observable,
      showModal: observable,
      msgUnreadCount: observable,
      msglastUpdatedTime: observable,
      permissions: observable,
      userLanguage: observable,
      categoryIconMapping: observable,
      setVehicleGroups: action,
      setActivityTypes: action,
      setShowModal: action,
      setMsgLastUpdatedTime: action,
      setMsgUnreadCount: action,
      vehiclesNotificationsList: observable,
      setVehiclesNotificationsList: action,
      mappedActivity: computed,
      authorizedMenus: computed,
      setPermissions: action,
      setUserLanguage: action,
      messageTypes: observable,
      maxFileSize: observable,
      setMessageTypes: action,
      setMaxFileSize: action,
    });
  }
  setVehicleGroups(vehicleGroups: IMultiSelect[]) {
    this.vehicleGroups = vehicleGroups;
  }
  setActivityTypes(activityTypes: IActivityType[]) {
    this.activityTypes = activityTypes;
  }
  setVehiclesList(vehicles: IVehicleData[]) {
    this.vehiclesList = vehicles;
  }
  setShowModal(flag: boolean) {
    this.showModal = flag;
  }
  setMsgLastUpdatedTime = (time: number): void => {
    this.msglastUpdatedTime = time;
  };
  setMsgUnreadCount = (count: number): void => {
    this.msgUnreadCount = count;
  };
  setVehiclesNotificationsList = (list: string[]): void => {
    this.vehiclesNotificationsList = list;
  };
  setPermissions = (permissions: string[]): void => {
    this.permissions = permissions;
  };
  setUserLanguage = (language: string): void => {
    this.userLanguage = language;
  };
  setCategoryIconMapping = (categoryIconMapping: ICategoryIconMapping[]) => {
    this.categoryIconMapping = categoryIconMapping;
  };
  get authorizedMenus() {
    return filterMenusByPermission(this.permissions)?.map((menu: IMenuItem) => menu.name);
  }
  get mappedActivity() {
    const options: IMultiSelect[] = [];
    this.activityTypes?.map((i: IActivityType) => {
      options.push({ label: i.name || i.code, value: i.code });
    });
    return options;
  }
  setMessageTypes(messageTypes: IMessageType[]) {
    this.messageTypes = messageTypes;
  }
  /**
   * Fn to set the maximum allowed file size.
   * @param fileSize in bytes.
   */
  setMaxFileSize(fileSize: number) {
    this.maxFileSize = fileSize;
  }
}
