import React from "react";
import ReactDOM from "react-dom";
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import TraceService from "../../../../../services/Trace.service";
import {
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
} from "@trimblemaps/trimblemaps-js/geojson";

export class TracesMapService {
  map: TrimbleMaps.Map = {} as TrimbleMaps.Map;
  popupRef: TrimbleMaps.Popup | null = null;
  traceService: TraceService = new TraceService();

  constructor(map: TrimbleMaps.Map) {
    this.map = map;
  }

  setMap(map: TrimbleMaps.Map) {
    this.map = map;
  }

  getMap() {
    return this.map;
  }

  addImage(img: string, fileName: string) {
    try {
      this.map?.loadImage(img, (err: unknown, img: any) => {
        if (err) {
          console.error(`Failed to load image ${fileName}:`, err);
          return;
        }
        if (img && !this.map.hasImage(fileName)) {
          this.map.addImage(fileName, img);
        }
      });
    } catch (error) {
      console.log("TraceMapService ~ loadImage ~ error:", error);
    }
  }

  loadImagesToMap(images: { url: string; name: string }[]) {
    images.forEach((img) => {
      this.addImage(img.url, img.name);
    });
  }

  fitBounds(lngLatBounds: TrimbleMaps.LngLatBounds, options?: TrimbleMaps.FitBoundsOptions) {
    try {
      if (lngLatBounds) {
        this.map?.fitBounds(lngLatBounds, {
          ...options,
          padding: 75,
          animate: false,
        });
      }
    } catch (error) {
      console.log("TraceMapService ~ fitBounds ~ error:", error);
    }
  }

  getSource(sourceName: string) {
    return this.map?.getSource(sourceName) as TrimbleMaps.GeoJSONSource;
  }

  addSource(sourceId: string, data: FeatureCollection<Geometry, GeoJsonProperties>, options?: any) {
    try {
      if (!this.map?.getSource(sourceId)) {
        this.map?.addSource(sourceId, {
          type: "geojson",
          data: data,
          ...options,
        });
      } else {
        this.updateSource(sourceId, data);
      }
    } catch (error) {
      console.log("TraceMapService ~ addSource ~ error:", error);
    }
  }

  updateSource(sourceId: string, data: FeatureCollection<Geometry, GeoJsonProperties>) {
    try {
      const source = this.map?.getSource(sourceId) as TrimbleMaps.GeoJSONSource;
      if (source) {
        source.setData(data);
      }
    } catch (error) {
      console.log("TraceMapService ~ updateSoureData ~ error:", error);
    }
  }

  removeSource(sourceId: string) {
    try {
      if (this.map?.getSource(sourceId)) {
        this.map?.removeSource(sourceId);
      }
    } catch (error) {
      console.log("TraceMapService ~ removeSource ~ error:", error);
    }
  }

  removeLayer(layerId: string) {
    try {
      if (this.map?.getLayer(layerId)) {
        this.map?.removeLayer(layerId);
      }
    } catch (error) {
      console.log("TraceMapService ~ removeLayer ~ error:", error);
    }
  }

  closePopup() {
    try {
      if (this.popupRef) {
        this.popupRef.remove();
        this.popupRef = null;
      }
    } catch (error) {
      console.log("TraceMapService ~ closePopup ~ error:", error);
    }
  }

  openPopup(feature: any, popupEle: any, showPopup: boolean, onClose?: () => void) {
    try {
      this.closePopup();
      const coordinates = feature?.geometry?.coordinates.slice();
      if (!showPopup && this.map) {
        this.popupRef = new TrimbleMaps.Popup();
        const htmlEle = document.createElement("div");
        ReactDOM.render(popupEle, htmlEle);
        this.popupRef?.setLngLat(coordinates).setDOMContent(htmlEle).addTo(this.map);
        if (!this.map?.getBounds()?.contains(coordinates)) {
          this.map.panTo(coordinates);
        }
        const element = document.getElementsByClassName("trimblemaps-popup-close-button")[0];
        const handleCloseButtonClick = () => {
          onClose?.();
          this.popupRef = null;
          element?.removeEventListener("click", handleCloseButtonClick);
        };
        // Remove any existing event listener before adding a new one
        element?.removeEventListener("click", handleCloseButtonClick);
        element?.addEventListener("click", handleCloseButtonClick);
      }
    } catch (error) {
      console.log("TraceMapService ~ openTracePopup ~ error:", error);
    }
  }
}
