export const API_PATH = {
  VEHICLES: "/vehicles",
  UMP: {
    GET_PERMISSIONS: "me/permissions",
  },
};
export const ERROR_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
};
export const SUMMATION = "sum";
export const DEFAULT_TIME = "0:00";
export const ERROR_CANCELED = "ERR_CANCELED";
export const ERROR_CUSTOMER_NOT_SELECTED = "customer.not.selected";
export const DATE_TIME_FORMAT = "DD/MM/YY - HH:mm";
export const DATE_FORMAT = "DD/MM/YY";
export const DATE_DISPLAY_FORMAT = "DD-MM-YY";
export const MINUTES_FORMAT = "mm";
export const HOURS_MINUTES_FORMAT = "HH:mm";

// Since the trace properties date and time has different formats for each language,
// we need to consider all the formats.
export const ALL_DATE_TIME_FORMATS = [
  "DD.MM.YY, hh:mm",
  "MM/DD/YY, hh:mm A",
  "DD/MM/YY hh:mm",
  "DD/MM/YYYY hh:mm",
  "DD-MM-YYYY hh:mm",
  "DD.MM.YYYY, hh:mm",
];

export const MEASUREMENT_UNITS = {
  WEIGHT: "kg",
  TEMPERATURE: "°C",
  SPEED: "km/h",
  DISTANCE: "km",
  MILEAGE: "kmpl",
  MINUTES: "minutes",
  HOURS: "h",
  METERS: "m",
  LITERS: "L",
};

export const ASSET_TYPES = {
  TRUCK: "TRUCK",
  TRAILER: "TRAILER",
  MOBILE: "MOBILE",
};

export const ACTIVITY_COLORS = {
  0: "#FF9D00", //Orange
  1: "#5E9331", //Green
  2: "#197CB6", //Blue
  3: "#009AD9", //Blue
  4: "#DF4EB2", //Pink
  5: "#E49325", //Orange
  6: "#FFBE00", //Yellow
  7: "#72A544", //Green
  8: "#005F9E", //Blue
  9: "#00437B", //Blue
  10: "#56E8DD", //Light Blue
  11: "#7C11BF", //Purple
  12: "#F4D1D3", //Pink
  13: "#BC0911", //Red
  14: "#6A6976", //Grey
  15: "#1B1A26", //Black
};

export enum STATE {
  "NEW" = 1,
  "RECEIVED" = 2,
  "ACCEPTED" = 3,
  "REFUSED" = 4,
  "BUSY" = 5,
  "CANCELLED" = 6,
  "FINISHED" = 7,
  "UPDATED" = 8,
  "REMOVED" = 9,
  "UNASSIGNED" = 10,
}

export const TASK_CONST = {
  TASKS: "Tasks",
  TASK: "Task",
};

export const VEHICLE_STATUS = {
  LATE: "Late",
  AT_RISK: "AtRisk",
  EARLY: "Early",
  ON_GOING: "Ongoing",
  ON_TIME: "OnTime",
  ACTIVE: "Active",
  IN_ACTIVE: "Inactive",
};

export const VEHICLE_REEFER_STATUS = {
  ON: "on",
  OFF: "off",
};

export const SORT_ORDERS = {
  ASC: "asc",
  DESC: "desc",
};

export const SEARCH_BOX_POSITION = {
  TOP: "top",
  BOTTOM: "bottom",
};

export enum FILTER_TYPES {
  "VEHICLE" = 1,
  "TASK" = 2,
  "LOCATION" = 3,
}

export const PATH = {
  VEHICLES: "/vehicles",
  DISPATCH: "/dispatch",
  SWITCH_CUSTOMER: "/switchCustomer",
  MESSAGES: "/messages",
  TRACES: "/history",
  CREW: "/crew",
};

export const TOAST_STYLE = {
  ERROR: "danger",
  SUCCESS: "success",
  WARNING: "warning",
  PRIMARY: "primary",
};

export enum CREW_MEMBER_TYPE {
  DRIVER = "driver",
  CO_DRIVER = "codriver",
  PASSENGER = "passenger",
}

export const DRIVING_TYPES = {
  RESTING: "RESTING",
  WAITING: "WAITING",
  WORKING: "WORKING",
  DRIVING: "DRIVING",
};

export const DRIVING_TYPES_COLORS = {
  RESTING: "#6A6976", //Grey
  WAITING: "#FFA500", //Yellow
  WORKING: "#D52A33", //Red
  DRIVING: "#009AD9", //Blue
};

export const TASKS_COLORS = {
  1: "#363545",
  2: "#363545",
  3: "#363545",
  4: "#363545",
  5: "#005F9E",
  6: "#363545",
  7: "#4a821f",
  8: "#363545",
  9: "#363545",
  10: "#363545",
};
//TODO - Need to reuse the variables already declared in scss files within react component
export const COLOR_VALUES = {
  WHITE: "#ffffff",
  BLUE_100: "#363545",
  BLUE_200: "#009ad9",
  BLUE_300: "#197cb6",
  BLUE_400: "#00437b",
  GRAY_450: "#8c8b96",
  GREEN_100: "#72a544",
  YELLOW_100: "#ffbe00",
  BLACK: "#000000",
  RED_100: "#f4d1d3",
  RED_200: "#D52A33",
};

export enum DETAILS_TAB {
  VEHICLE = "VEHICLE",
  DRIVING = "DRIVING",
  MESSAGE = "MESSAGE",
}

export const DRIVING_TIMES_VALUES = {
  NIGHT_TIME_MAX: 7,
  REDUCED_RESTING_HOURS: 9,
  EXTENDED_DRIVING_HOURS: 10,
  WEEKLY_EXTENDED_DRIVING_AVAILABLE: 2,
  WEEKLY_REDUCED_RESTING_AVAILABLE: 3,
};

// Unread states - 0,1,10
export const MESSAGE_STATUS = {
  NEW: 0,
  RECEIVED: 1,
  READ: 2,
  ERROR: 3,
  ANSWERED: 4,
  MARK_UNREAD: 10,
};

/**
 * Directions
 * 0: to device
 * 1: from device
 */
export enum MESSAGE_DIRECTION {
  TO_DEVICE = "TO_DEVICE",
  FROM_DEVICE = "FROM_DEVICE",
}

/* Breaks the string into array of string matching the pattern.
    input text: This is a [sample|style://color:#007575] text. This is another word.
    output matched array:  ['This is a [sample|style://color:#007575]', ' text. This is another word.']
*/
export const TEXT_PATTERN = /((.*?)(\[(.*?)\])|(.+))/;

/* Breaks the string into array of string matching the pattern.
    input text: This is a [dl link=document_link]document_name[/dl] text. This is another word.
    output matched array:  ['This is a [dl link=document_link]document_name[/dl]', ' text. This is another word.']
*/
export const DOCUMENT_PATTERN = /((.*?)(\[(.*?)\](.*?)\[\/(.*?)\])|(.+))/;

export const LOCALSTORAGE_KEYS = {
  FILTERS: "filters",
  MAP_STYLE: "mapStyle",
  MAP_LAYER: "mapLayer",
};

export const VIEW_TAB = {
  LIST_VIEW: "listView",
  MAP_VIEW: "mapView",
};

export const TRIMBLEMAPS_POPUP_CLOSE_BUTTON = "trimblemaps-popup-close-button";
export const PERMISSIONS = {
  FOLLOWUP: "followup.followup_access",
  MESSAGING: "followup.messaging",
  TRACES: "followup.history",
  DMS: "fleetcockpit.docmgmt",
  PLACES: "places.places",
  REPORTING: "fleetcockpit.pbireport",
};

export const MENUS = {
  VEHICLES: "Vehicles",
  MESSAGE_CENTER: "Message Center",
  DMS: "DMS",
  HISTORY: "History",
  PLACES: "Places",
  REPORTING: "Reporting",
};

export const HISTORY_VIEWS = {
  DEFAULT: "default",
  ACTIVITY: "activity",
  DRIVING_TIMES: "drivingTimes",
  FUEL: "fuel",
};

export const ACTIVITY_CATEGORY = {
  0: "other",
  1: "accident",
  2: "border_crossing",
  3: "breakdown",
  4: "customs",
  5: "document",
  6: "drive",
  7: "empty",
  8: "fuel",
  9: "load",
  10: "login",
  11: "logout",
  12: "rent",
  13: "rest",
  14: "roadside_check",
  15: "tether",
  16: "trainferry",
  17: "training",
  18: "traffic",
  19: "unknown",
  20: "unload",
  21: "untether",
  22: "vehicle_check",
  23: "wait",
  24: "wash",
  25: "weight",
  26: "workshop",
};

export const DRIVING_TIMES_CATEGORY = {
  6: { icon: "invalid_driver_card" },
  7: { icon: "driving_without_drivercard" },
  8: { icon: "driving_without_driver" },
  44: { icon: "border_crossing" },
  84: { icon: "driving_times_driving_violation_event" },
  85: { icon: "driving_times_total_driving_violation_event" },
  87: { icon: "driving_times_amplitude_violation_event" },
  88: { icon: "remote_tacho_download_failed" },
};

export enum ViewMode {
  LIST = "list",
  CARD = "card",
}
