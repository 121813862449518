import React from "react";
import { OverlayTrigger, Tooltip } from "@trimbleinc/modus-react-bootstrap";
import DateTime from "../../../../molecules/DateTime/DateTime";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import warningIcon from "../../../../../assets/images/warning.svg";
import doorOpenIcon from "../../../../../assets/images/door_open.svg";
import doorClosedIcon from "../../../../../assets/images/door_closed.svg";
import { getTranslatedValue } from "../../../../../common/utils";
export interface IZoneDetailsProps {
  icon?: string;
  temp?: string;
  label?: string;
  timestamp?: number;
  warning?: boolean;
  doorStatus?: boolean;
  tempIndex?: number;
}
const ZoneDetails = (props: IZoneDetailsProps) => {
  const value = props.temp || props.doorStatus;
  return (
    <>
      {value != null && (
        <div className={`d-flex truncate-text zone-value`}>
          {props?.icon && <img src={props.icon} className="mr-1" title={props.label} />}
          <OverlayTrigger
            placement="auto"
            trigger={["hover", "click"]}
            delay={{ show: 300, hide: 0 }}
            overlay={
              props.timestamp ? (
                <Tooltip id="zone-value-tooltip">
                  <div className="d-flex flex-column align-items-start tooltip-info">
                    {props.temp != null ? (
                      <span>{props.temp}</span>
                    ) : (
                      props.doorStatus != null && (
                        <span>
                          {getTranslatedValue(
                            "TTM.followup.",
                            props.doorStatus ? "doorOpen" : "doorClosed",
                          )}
                        </span>
                      )
                    )}
                    <span>
                      {`${i18nInstance.t("TTM.followup.lastUpdated")} : `}
                      <DateTime dateTime={props.timestamp} />
                    </span>
                  </div>
                </Tooltip>
              ) : (
                <span />
              )
            }
          >
            {props.temp != null ? (
              <span>{props?.tempIndex ? `${props.tempIndex} : ${props.temp}` : props.temp}</span>
            ) : props.doorStatus != null ? (
              <span className="ml-2 zone-icon">
                <img src={props.doorStatus ? doorOpenIcon : doorClosedIcon} />
              </span>
            ) : (
              <span />
            )}
          </OverlayTrigger>
          {props?.warning && (
            <img
              src={warningIcon}
              className="warningIcon ml-1"
              title={i18nInstance.t("TTM.followup.warning")}
            />
          )}
        </div>
      )}
    </>
  );
};
export default ZoneDetails;
