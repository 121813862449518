import React, { useEffect, useRef, useState } from "react";
import { AxiosResponse } from "axios";
import Timeline from "@ttl/shared-react-library/src/packages/timeline/components/TimeLine";
import "./TracesTimeline.scss";
import { observer } from "mobx-react-lite";
import TraceService from "../../../../../services/Trace.service";
import { DATE_DISPLAY_FORMAT, ERROR_CANCELED } from "../../../../../common/constants";
import { useTraceStore } from "../../../../../contexts/traces.context";
import {
  getAppConfig,
  getEndTimeFromDate,
  getStartTimeFromDate,
} from "../../../../../common/utils";
import errorIcon from "../../../../../assets/images/error_icon.svg";
import infoIcon from "../../../../../assets/images/info.svg";
import {
  getActivityData,
  getDrivingTimesData,
  getTasksData,
  TIMELINE_CATEGORY,
  TIMELINE_TEMPLATES,
} from "../../../../../common/timelineUtils";
import VehicleSummary from "../../../../domain/Vehicle/molecules/VehicleSummary/VehicleSummary";
import DrivingTimesSummary from "../../../../domain/DrivingTime/organisms/DrivingTimesSummary/DrivingTimesSummary";
import { useAppStore } from "../../../../../contexts/app.context";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { ITimelineDetails, ITraceOutletContext } from "../../../../../models/TraceModel";
import debounce from "lodash/debounce";
import { useOutletContext } from "react-router-dom";
import useMobileDetect from "../../../../../hooks/useMobileDetect/useMobileDetect";
import { ITimeline } from "@ttl/shared-react-library/src/packages/timeline/models/TimelineModel";

const TracesTimeline = () => {
  const isMobile = useMobileDetect();
  const traceStore = useTraceStore();
  const appStore = useAppStore();
  const traceService = new TraceService();
  const controller = useRef<AbortController>();
  const [timelineRange, setTimelineRange] = useState({ startTime: "", endTime: "" });

  const [timelineData, setTimelineData] = useState({} as { [key: string]: ITimelineDetails });
  const [updatedTimelineData, setUpdatedTimelineData] = useState<string | ITimelineDetails[]>();
  const timelineContainerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [resetData, setResetData] = useState<number>(0);
  const [noData, setNoData] = useState<boolean>(false);
  const errorCountRef = useRef(0);
  const apiRequestCountRef = useRef(0);
  const noDataCountRef = useRef(0);
  const timelineContext = useOutletContext<ITraceOutletContext>();

  const getShowLineAtTimestamp = () => {
    if (
      timelineContext?.highlightedTraceIds &&
      timelineContext?.highlightedTraceIds?.length === 1
    ) {
      const highlightedTrace = timelineContext?.masterTracesList.find(
        (trace) => trace.id == timelineContext?.highlightedTraceIds[0],
      );
      return highlightedTrace?.timestamp;
    } else {
      return timelineContext?.selectedTrace?.timestamp;
    }
  };

  const debounceDomainChange = useRef(
    debounce((startTime: number, endTime: number, data?: any) => {
      timelineContext?.handleOnTimelineChange(startTime, endTime, data);
    }, 200),
  ).current;

  const onDomainChange = (startTime: number, endTime: number, data?: any) => {
    if (!isError && !noData && !isMobile) {
      debounceDomainChange(startTime, endTime, data);
    }
  };

  const getTranslatedLabel = (category: string) => {
    return category.toLowerCase() === TIMELINE_CATEGORY.ACTIVITY
      ? i18nInstance.t("TTM.followup.activity_other")
      : i18nInstance.t("TTM.followup.task_other");
  };

  /**
   * Reset the timeline component when the trace type or language is changed.
   * setTimelineData is used to store the timeline data for each category after consolidation.
   */
  const resetComponent = (
    data: { [key: string]: ITimelineDetails },
    filterFn?: (timeline: ITimeline) => boolean,
  ) => {
    try {
      setUpdatedTimelineData("");
      setResetData(resetData + 1);
      const consolidatedTimeline: ITimelineDetails[] = [];

      if (data && Object.keys(data).length > 0) {
        Object.keys(data).forEach((key) => {
          const timeline = data[key];

          if (filterFn && filterFn(timeline)) {
            const updatedTimeline = {
              ...timeline,
              timelines: timeline.timelines.map((row) => ({
                ...row,
                name: getTranslatedLabel(timeline.category),
              })),
            };

            setTimelineData((prevData) => ({
              ...prevData,
              [key]: updatedTimeline,
            }));

            consolidatedTimeline.push(updatedTimeline);
          } else {
            consolidatedTimeline.push(timeline);
          }
        });

        setTimeout(() => {
          setUpdatedTimelineData(consolidatedTimeline);
        }, 10);
      }
    } catch (error) {
      console.log("resetComponent ~ error:", error);
    }
  };

  const handleTimelineResponse = (data: any) => {
    try {
      if (!data) return;
      const template = TIMELINE_TEMPLATES[data.category as keyof typeof TIMELINE_TEMPLATES];
      const responseData = {
        ...data,
        timelines: data?.timelines?.map((timeline: any) => ({
          ...timeline,
          ...template,
          name: timeline?.name || data?.category,
        })),
      };
      return responseData;
    } catch (error) {
      console.log("handleTimelineResponse ~ error:", error);
    }
  };

  const fetchTimelineDetails = (category: TIMELINE_CATEGORY) => {
    apiRequestCountRef.current = apiRequestCountRef.current + 1;
    try {
      if (traceStore?.traceFilter?.terminalId && traceStore?.traceFilter?.selectedDate) {
        const selectedDate = traceStore.traceFilter.selectedDate.format(DATE_DISPLAY_FORMAT);
        const terminalId = traceStore.traceFilter.terminalId;
        const startTime = getStartTimeFromDate(selectedDate);
        const endTime = getEndTimeFromDate(selectedDate);
        setTimelineRange({ startTime, endTime });
        traceService
          .getTimelineDetails(category, terminalId, startTime, endTime, controller.current)
          .then((res: AxiosResponse) => {
            let response = handleTimelineResponse(res.data);
            if (response) {
              if (category === TIMELINE_CATEGORY.ACTIVITY) {
                response =
                  appStore?.activityTypes && getActivityData(response, appStore.activityTypes);
              } else if (category === TIMELINE_CATEGORY.DRIVING_TIMES) {
                response = getDrivingTimesData(response);
              } else if (category === TIMELINE_CATEGORY.TASKS) {
                response = getTasksData(response);
              }
              setUpdatedTimelineData(response);
              setTimelineData((prevData) => ({
                ...prevData,
                [category]: response,
              }));
              setTimeout(() => {
                setIsLoading(false);
              }, 10);
              setIsError(false);
            } else {
              noDataCountRef.current = noDataCountRef.current + 1;
              if (noDataCountRef.current === apiRequestCountRef.current) {
                setIsLoading(false);
                setNoData(true);
                setIsError(false);
              }
            }
          })
          .catch((error) => {
            if (error && error?.code !== ERROR_CANCELED) {
              errorCountRef.current = errorCountRef.current + 1;
              if (errorCountRef.current === apiRequestCountRef.current) {
                setIsLoading(false);
                setIsError(true);
              }
            }
          });
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (traceStore?.traceFilter?.selectedDate) {
      const selectedDate = traceStore?.traceFilter?.selectedDate.format(DATE_DISPLAY_FORMAT);
      const startTime = getStartTimeFromDate(selectedDate);
      const endTime = getEndTimeFromDate(selectedDate);
      if (startTime !== timelineRange.startTime && endTime !== timelineRange.endTime) {
        setTimelineRange({ startTime: startTime, endTime: endTime });
      }
      controller.current = new AbortController();
      setTimelineData({});
      setResetData(resetData + 1);
      setIsLoading(true);
      setIsError(false);
      setNoData(false);
      errorCountRef.current = 0;
      apiRequestCountRef.current = 0;
      noDataCountRef.current = 0;
      fetchTimelineDetails(TIMELINE_CATEGORY.ACTIVITY);
      fetchTimelineDetails(TIMELINE_CATEGORY.DRIVING_TIMES);
      getAppConfig()?.showTaskTimeline && fetchTimelineDetails(TIMELINE_CATEGORY.TASKS);
      return () => controller.current?.abort();
    }
  }, [traceStore?.traceFilter?.selectedDate, traceStore?.traceFilter?.terminalId]);

  useEffect(() => {
    resetComponent(timelineData);
  }, [traceStore?.traceFilter?.traceTypes]);

  useEffect(() => {
    if (timelineContainerRef) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setDimensions({
            width: entry.contentRect.width,
            height: entry.contentRect.height,
          });
        }
      });
      observer.observe(timelineContainerRef.current as unknown as Element);
      return () => {
        setDimensions({ width: 0, height: 0 });
        setTimelineData({});
        setIsError(false);
        setNoData(false);
        observer.disconnect();
        debounceDomainChange?.cancel();
      };
    }
  }, []);

  useEffect(() => {
    resetComponent(
      timelineData,
      (timeline) =>
        timeline.category.toLowerCase() === TIMELINE_CATEGORY.TASKS ||
        timeline.category.toLowerCase() === TIMELINE_CATEGORY.ACTIVITY,
    );
  }, [appStore?.userLanguage]);
  return (
    <div className="timeline-container">
      <div className="timeline-summary">
        {traceStore?.traceFilter?.terminalId && traceStore?.traceFilter?.selectedDate && (
          <VehicleSummary
            vehicleId={traceStore.traceFilter.terminalId}
            startTime={timelineRange.startTime}
            endTime={timelineRange.endTime}
          />
        )}
        <DrivingTimesSummary
          drivingTimesSummary={timelineData[TIMELINE_CATEGORY.DRIVING_TIMES]?.historySummaries}
        />
      </div>
      <div className="timeline-content" ref={timelineContainerRef}>
        {isError && !isLoading && (
          <div className="timeline-error">
            <img className="timeline-error-icon" src={errorIcon} />
            {`${i18nInstance.t("TTM.followup.generic.error")}`}
          </div>
        )}
        {!isError && !isLoading && noData ? (
          <div className="timeline-error">
            <img className="timeline-error-icon" src={infoIcon} />
            {`${i18nInstance.t("TTM.followup.error.noDataAvailable")}`}
          </div>
        ) : (
          !isError &&
          timelineRange &&
          timelineRange.startTime &&
          timelineRange.endTime && (
            <Timeline
              reset={resetData}
              loading={isLoading}
              width={dimensions.width}
              height={dimensions.height}
              endDate={+timelineRange.endTime}
              startDate={+timelineRange.startTime}
              showLineAt={getShowLineAtTimestamp()}
              data={JSON.stringify(updatedTimelineData)}
              handleDomainChange={onDomainChange}
              backBtnLabel={i18nInstance.t("TTM.followup.back")}
            />
          )
        )}
      </div>
    </div>
  );
};

export default observer(TracesTimeline);
