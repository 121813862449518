import React from "react";
import moment from "moment";
import "./TracesMapMobileFooter.scss";
import { useNavigate, useOutletContext } from "react-router-dom";
import { sendMonitoringLogs } from "../../../../../../common/utils";
import { ITraceOutletContext } from "../../../../../../models/TraceModel";
import { PATH, DATE_DISPLAY_FORMAT } from "../../../../../../common/constants";
import CustomDatePicker from "../../../../molecules/CustomDatePicker/CustomDatePicker";
import { ReactComponent as FilterIcon } from "../../../../../../assets/images/filterIcon.svg";
import { ReactComponent as FilterIconActive } from "../../../../../../assets/images/filterIconActive.svg";

export interface IProps {
  handleOnFilterOpen?: () => void;
}

const TracesMapMobileFooter = (props: IProps) => {
  const { handleOnFilterOpen } = props;
  const navigate = useNavigate();
  const mapContext = useOutletContext<ITraceOutletContext>();

  const isFilterActive =
    mapContext?.traceFilter?.traceTypes && mapContext?.traceFilter?.traceTypes.length > 0;

  const handleOnDateSelect = (date: Date | null) => {
    try {
      sendMonitoringLogs("HISTORY_FILTER_DATE_SELECT");
      date &&
        navigate(
          `${PATH.TRACES}/${mapContext?.terminalId}/${moment(date).format(DATE_DISPLAY_FORMAT)}`,
        );
    } catch (error) {
      console.log("TracesMapMobileFooter ~ handleOnDateSelect ~ error:", error);
    }
  };

  return (
    <div className="trace-map-footer">
      <CustomDatePicker
        selectedDate={mapContext?.traceFilter?.selectedDate}
        onDateSelect={handleOnDateSelect}
      />
      <div className="trace-map-filter-btn flex-center" onClick={handleOnFilterOpen}>
        {isFilterActive ? <FilterIconActive /> : <FilterIcon />}
      </div>
    </div>
  );
};

export default TracesMapMobileFooter;
