import React from "react";
import DischargeAir from "../../../../../assets/images/dischargeAir.svg";
import ReturnAir from "../../../../../assets/images/returnAir.svg";
import Temperature from "../../../../../assets/images/thermostat.svg";
import ZoneDetails from "../ZoneDetails/ZoneDetails";
import { getTranslatedValue, getZoneTemp, isWarning } from "../../../../../common/utils";
import { ISensorInfo } from "../../../../../models/VehicleModel";
import "./ZoneCardView.scss";
export interface IZoneCardViewProps {
  temp1: ISensorInfo;
  temp2: ISensorInfo;
  dischargeAir: ISensorInfo;
  returnAir: ISensorInfo;
}
const ZoneCardView = (props: IZoneCardViewProps) => {
  const { temp1, temp2, dischargeAir, returnAir } = props;
  const tempSensors = [temp1, temp2].filter((item) => item && item.temp);
  return (
    <div className="zone-details-container">
      <div className="zone-details-temp">
        {tempSensors.map((item, index) => (
          <ZoneDetails
            key={`${index + item?.temp}`}
            warning={isWarning(item)}
            icon={Temperature}
            label={`LOG${index + 1}`}
            temp={getZoneTemp(item?.temp)}
            timestamp={item?.timestamp}
            tempIndex={index + 1}
          />
        ))}
      </div>
      <div className="zone-details-air">
        {[dischargeAir, returnAir].map((item, index) => (
          <ZoneDetails
            key={`${index + item?.temp}`}
            icon={index === 0 ? DischargeAir : ReturnAir}
            label={
              index === 0
                ? getTranslatedValue("TTM.followup.", `dischargeAir`)
                : getTranslatedValue("TTM.followup.", `returnAir`)
            }
            temp={getZoneTemp(item?.temp)}
            timestamp={item?.timestamp}
          />
        ))}
      </div>
    </div>
  );
};
export default ZoneCardView;
