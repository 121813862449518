import React, { useRef, useState } from "react";
import "./TracesViewSelect.scss";
import { useOutletContext } from "react-router-dom";
import { HISTORY_VIEWS } from "../../../../common/constants";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { sendMonitoringLogs } from "../../../../common/utils";
import Card from "@trimbleinc/modus-react-bootstrap/esm/Card";
import useOnOutsideClick from "@utilityjs/use-on-outside-click";
import { IIconLabelCardOption } from "../../../../common/models";
import { useTraceStore } from "../../../../contexts/traces.context";
import { ITraceOutletContext } from "../../../../models/TraceModel";
import IconLabelCard from "../../../global/molecules/IconLabelCard/IconLabelCard";

//Icon imports
import betaImg from "../../../../assets/images/beta.svg";
import fuelView from "../../../../assets/images/trace/fuel_icon.svg";
import defaultView from "../../../../assets/images/trace/default_view.svg";
import activityView from "../../../../assets/images/trace/activity_view.svg";
import drivingTimesView from "../../../../assets/images/trace/driving_times_view.svg";

const TracesViewSelect = () => {
  const traceStore = useTraceStore();
  const context = useOutletContext<ITraceOutletContext>();
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const traceViewSelectContainerRef = useRef<HTMLDivElement>(null);

  useOnOutsideClick(traceViewSelectContainerRef, () => {
    setShowOptions(false);
  });

  const traceViewOptions: IIconLabelCardOption[] = [
    {
      id: HISTORY_VIEWS.DEFAULT,
      img: defaultView,
      key: "TTM.followup.default",
    },
    {
      id: HISTORY_VIEWS.ACTIVITY,
      img: activityView,
      key: "TTM.followup.activity",
    },
    {
      id: HISTORY_VIEWS.DRIVING_TIMES,
      img: drivingTimesView,
      key: "TTM.followup.drivingTimes",
    },
    {
      id: HISTORY_VIEWS.FUEL,
      img: fuelView,
      key: "TTM.followup.fuel",
    },
  ];

  const iconAndLabel = traceViewOptions.find((option) => option.id === traceStore?.selectedView);

  const handleTraceViewSelect = async (selectedView: string) => {
    traceStore?.setSelectedView(selectedView);
    setShowOptions(false);
    sendMonitoringLogs(`HISTORY_SELECT_${selectedView?.toUpperCase()}_VIEW`);
  };

  const handleOnClick = () => {
    setShowOptions(!showOptions);
    sendMonitoringLogs(showOptions ? "HISTORY_VIEW_MENU_OPEN" : "HISTORY_VIEW_MENU_CLOSE");
    context?.handleSelectedTask(null);
    context?.handleSelectedTrace(null);
  };

  return (
    <div ref={traceViewSelectContainerRef} className="trace-view-container">
      <img
        src={iconAndLabel?.img}
        alt="trace-view-icon"
        title={i18nInstance.t(iconAndLabel?.key || "")}
        className="trace-view-menu-icon"
        onClick={handleOnClick}
      />
      {showOptions && (
        <Card border="dark" className="shadow trace-view-menu-card">
          <img className="beta-img" src={betaImg} />
          <IconLabelCard
            options={traceViewOptions}
            handleClick={handleTraceViewSelect}
            active={traceStore?.selectedView}
          />
        </Card>
      )}
    </div>
  );
};

export default TracesViewSelect;
