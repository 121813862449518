import React, { useEffect, useState } from "react";
import MessageConversations from "../MessageConversations/MessageConversations";
import { useAppStore } from "../../../contexts/app.context";
import MessageService from "../../../services/Message.service";
import { AxiosResponse } from "axios";
import { IMaxFileSize } from "../../../models/MessageModel";
import { observer } from "mobx-react-lite";
import { handleError } from "../../../common/helpers/ErrorHandler";

export interface IMessageConversationWrapperProps {
  unitId?: string;
  showVehicleHeader?: boolean;
  driverName?: string;
}
const MessageConversationWrapper = (props: IMessageConversationWrapperProps) => {
  const appStore = useAppStore();
  const messageService = new MessageService();
  const [loading, setLoading] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState("");
  const loadMaxFileSize = () => {
    messageService
      .getFileMaxSize()
      .then((response: AxiosResponse<IMaxFileSize>) => {
        if (response?.data && response?.data?.maxDocSize) {
          const maxFieSize = response.data.maxDocSize * 1000;
          appStore?.setMaxFileSize(maxFieSize);
        }
      })
      .catch((e) => console.log("loadMaxFileSize ~ error", e));
  };

  const loadMessageTypes = () => {
    messageService
      .getMessageTypes()
      .then((response) => {
        if (response && response.data) {
          loadMaxFileSize();
          appStore?.setMessageTypes(response.data);
        }
        setLoading(false);
      })
      .catch((e) => {
        setErrMessage(handleError(e));
        setLoading(false);
      });
  };
  useEffect(() => {
    if (appStore?.messageTypes && appStore?.messageTypes?.length > 0) {
      loadMessageTypes();
    }
  }, [appStore?.userLanguage]);
  useEffect(() => {
    if (appStore?.messageTypes?.length === 0) {
      setLoading(true);
      loadMessageTypes();
    }
  }, []);
  return (
    <>
      {!errMessage && !loading && (
        <MessageConversations
          unitId={props?.unitId}
          showVehicleHeader={props.showVehicleHeader}
          driverName={props.driverName}
        />
      )}
      {errMessage && !loading && <div className="msg-conversation-error ml-2">{errMessage}</div>}
    </>
  );
};

export default observer(MessageConversationWrapper);
