import React, { useEffect, useState } from "react";
import { ITrace, ITraceType } from "../../../../models/TraceModel";
import backIcon from "../../../../assets/images/arrow_back.svg";
import "./TraceViewDetailsPopup.scss";
import { find } from "lodash";
import TraceDetails from "../../../domain/Trace/organisms/TraceDetails/TraceDetails";
import { IActivityType } from "../../../../models/VehicleModel";
import { getTraceNameIconColor, modifyCustomSvgIcon } from "../../../../common/tracesUtils";
import { COLOR_VALUES } from "../../../../common/constants";
import { CustomIcons } from "../../../../assets/ts/customIcons";
import CustomTraceView from "../CustomTraceView/CustomTraceView";

export interface ITraceViewDetailsPopupProps {
  trace: ITrace;
  traceTypeName?: string;
  traceTypes?: ITraceType[];
  activityTypes?: IActivityType[];
  terminalId?: string;
  selectedView?: string;
  showCustomView?: boolean;
  showTraceProperties?: boolean;
  onBack?: () => void;
  onClose?: () => void;
}

const TraceViewDetailsPopup = (props: ITraceViewDetailsPopupProps) => {
  const [image, setImage] = useState<string>("");
  const [traceName, setTraceName] = useState<string>("");

  const fetchImage = (src: string, color: string) => {
    fetch(src)
      .then((response) => response.text())
      .then((svgText) => setImage(modifyCustomSvgIcon(svgText, color)));
  };

  const fetchTraceDetails = () => {
    const { traceTypeName, customIcon, color } = getTraceNameIconColor(
      props?.selectedView,
      props?.trace,
      props?.activityTypes || [],
      props?.traceTypes || [],
    );
    setTraceName(traceTypeName);
    if (customIcon) {
      const icon = CustomIcons.find((i) => i.name === customIcon);
      if (icon) {
        fetchImage(icon?.src, color || COLOR_VALUES.GRAY_450);
      }
    }
  };

  useEffect(() => {
    fetchTraceDetails();
  }, [props?.trace?.id]);

  return (
    <div className="popup-details-container">
      {props?.onBack && <img src={backIcon} className="back-icon" onClick={props.onBack} />}
      {props?.showCustomView ? (
        <CustomTraceView
          trace={props?.trace}
          terminalId={props?.terminalId}
          traceName={traceName}
          image={image}
          onClose={props?.onClose}
        />
      ) : (
        <TraceDetails
          trace={props?.trace}
          terminalId={props?.terminalId}
          activityName={
            props?.activityTypes?.find(
              (activityType) => activityType.id === props?.trace?.eventTypeId,
            )?.name
          }
          traceType={find(props?.traceTypes, { traceType: props?.trace?.type })?.name}
          showTimestamp={true}
          showTraceProperties={props?.showTraceProperties}
          onClose={props?.onClose}
        />
      )}
    </div>
  );
};

export default TraceViewDetailsPopup;
