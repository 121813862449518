import React from "react";
import "./TrailerDetails.scss";
import { IStatus, ITrailer } from "../../../../../models/VehicleModel";
import TitleView from "../../../../molecules/TitleView/TitleView";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { MEASUREMENT_UNITS, ViewMode } from "../../../../../common/constants";
import { getDuration } from "../../../../../common/duration";
import { formatZones } from "../../../../../common/utils";
import VehicleZones from "../../molecules/VehicleZones/VehicleZones";
import mileage from "../../../../../assets/images/mileage.svg";
import tether from "../../../../../assets/images/tether.svg";
import weight from "../../../../../assets/images/weight.svg";
import ReeferEngine from "../../molecules/ReeferEngine/ReeferEngine";

export interface ITrailerDetailsProps {
  trailer: ITrailer;
  status: IStatus;
}

const TrailerDetails = (props: ITrailerDetailsProps) => {
  const trailer = props.trailer;
  const separator = ":";
  const renderIcon = (icon: string, value: string) => {
    return (
      <div className="mt-1">
        <span className="mr-1">
          <img src={icon}></img>
        </span>
        {value}
      </div>
    );
  };

  return (
    trailer && (
      <>
        <div className="vehicle-title-block trailer">
          {trailer?.name ? (
            <TitleView type={trailer.type} title={trailer.name} status={props.status} />
          ) : null}
        </div>
        <div className="vehicle-details d-flex flex-row flex-wrap ml-4">
          {trailer?.mileage ? (
            <div className="vehicle-property trailer-mileage">
              {renderIcon(
                mileage,
                `${i18nInstance.t("TTM.followup.mileage")} ${separator} ${trailer.mileage}${
                  MEASUREMENT_UNITS.MILEAGE
                }`,
              )}
            </div>
          ) : (
            <></>
          )}
          {trailer?.tetherTime ? (
            <div className="vehicle-property trailer-tethertime">
              {renderIcon(
                tether,
                `${i18nInstance.t("TTM.followup.tetheringTime")} ${separator} ${getDuration(
                  trailer.tetherTime,
                )}`,
              )}
            </div>
          ) : (
            <></>
          )}
          {trailer?.weight ? (
            <div className="vehicle-property trailer-weight">
              {renderIcon(
                weight,
                `${i18nInstance.t("TTM.followup.weight")} ${separator} ${trailer?.weight}${
                  MEASUREMENT_UNITS.WEIGHT
                }`,
              )}
            </div>
          ) : (
            <></>
          )}
          {trailer?.reeferEngine ? <ReeferEngine reefer={trailer.reeferEngine} /> : <></>}
          {trailer?.zones && trailer.zones?.length > 0 ? (
            <div className="vehicle-property temperature">
              <VehicleZones zones={formatZones(trailer?.zones)} viewMode={ViewMode.CARD} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    )
  );
};
export default TrailerDetails;
