import React, { useEffect, useState } from "react";
import TaskLayer from "../TaskLayer/TaskLayer";
import { useOutletContext } from "react-router-dom";
import { LngLatBounds } from "@trimblemaps/trimblemaps-js";
import TraceService from "../../../../../../../services/Trace.service";
import { DATE_DISPLAY_FORMAT } from "../../../../../../../common/constants";
import { useTraceStore } from "../../../../../../../contexts/traces.context";
import { TIMELINE_CATEGORY } from "../../../../../../../common/timelineUtils";
import TracesTaskView from "../../../../../molecules/TracesTaskView/TracesTaskView";
import useMobileDetect from "../../../../../../../hooks/useMobileDetect/useMobileDetect";
import { ITimelineDetails, ITraceOutletContext } from "../../../../../../../models/TraceModel";
import { getStartTimeFromDate, getEndTimeFromDate } from "../../../../../../../common/utils";
import MapMenuContainer from "../../../../../../organisms/MapView/MapMenuContainer/MapMenuContainer";

export interface ITaskLayerWrapperProps {
  map?: TrimbleMaps.Map;
  updateFitBounds?: (bounds: LngLatBounds) => void;
}
const TaskLayerWrapper = (props: ITaskLayerWrapperProps) => {
  const traceStore = useTraceStore();
  const traceService = new TraceService();
  const isMobile = useMobileDetect();
  const [taskData, setTaskData] = useState<ITimelineDetails | null>(null);
  const [showTaskView, setShowTaskView] = useState<boolean>(false);
  const mapContext = useOutletContext<ITraceOutletContext>();
  const loadTaskData = (flag: boolean) => {
    if (flag) {
      setShowTaskView(true);
      if (traceStore?.traceFilter?.terminalId && traceStore?.traceFilter?.selectedDate) {
        const selectedDate = traceStore.traceFilter.selectedDate.format(DATE_DISPLAY_FORMAT);
        const terminalId = traceStore.traceFilter.terminalId;
        const startTime = getStartTimeFromDate(selectedDate);
        const endTime = getEndTimeFromDate(selectedDate);
        traceService
          ?.getTimelineDetails(TIMELINE_CATEGORY.TASKS, terminalId, startTime, endTime)
          ?.then((res: any) => {
            if (res && res.data) {
              setTaskData(res.data);
            }
          });
      }
    } else {
      setShowTaskView(false);
      setTaskData(null);
    }
  };
  useEffect(() => {
    setTaskData(null);
    if (showTaskView) {
      loadTaskData(true);
    }
  }, [traceStore?.traceFilter?.terminalId, traceStore?.traceFilter?.selectedDate]);
  return (
    <>
      {isMobile ? (
        <MapMenuContainer handleTaskData={loadTaskData} showTasks={true} />
      ) : (
        <TracesTaskView handleTaskData={loadTaskData} />
      )}
      <TaskLayer
        map={props.map}
        taskData={taskData}
        showTaskView={showTaskView}
        resetLayer={mapContext?.resetTraces}
        handleSelectedTask={mapContext?.handleSelectedTask}
        handleSelectedTrace={mapContext?.handleSelectedTrace}
        updateFitBounds={props.updateFitBounds}
      />
    </>
  );
};
export default TaskLayerWrapper;
