import React from "react";
import Dash from "../../atoms/Dash/Dash";
import "./ProgressBar.scss";
export interface IProgressBarProps {
  label?: string;
  min: number;
  max: number;
  now?: number;
}
const defaultProps: IProgressBarProps = {
  min: 0,
  max: 100,
};
export enum ProgessColorCode {
  DANGER = "danger",
  WARNING = "warning",
  NORMAL = "normal",
  UNKNOWN = "unknown",
}
const ProgressBar = (props: IProgressBarProps) => {
  const getProgressStatus = (val: number) => {
    let progressStatus = ProgessColorCode.UNKNOWN;
    if (val < 10) {
      progressStatus = ProgessColorCode.DANGER;
    } else if (val >= 10 && val <= 20) {
      progressStatus = ProgessColorCode.WARNING;
    } else if (val > 20) {
      progressStatus = ProgessColorCode.NORMAL;
    }
    return progressStatus;
  };

  return props.now != undefined ? (
    <div className="d-flex flex-row progressBar justify-content-center align-items-center">
      <span className="progressBar_label mr-2">{props.now}%</span>
      <div className="d-flex flex-grow-1 progressBar__container">
        <span
          className={`progressBar__status ${getProgressStatus(props.now)}`}
          style={{
            width: `${props.now}%`,
          }}
        ></span>
      </div>
    </div>
  ) : (
    <Dash />
  );
};
ProgressBar.defaultProps = defaultProps;
export default ProgressBar;
