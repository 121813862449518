import React, { useContext, useState } from "react";
import "./TracesMapContainer.scss";
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import AuthContext from "@ttl/shared-react-library/src/auth/AuthContext";
import { getAppConfig } from "../../../../../common/utils";
import useMobileDetect from "../../../../../hooks/useMobileDetect/useMobileDetect";
import MapComponent from "../../../../global/organisms/MapComponent/MapComponent";
import { TracesMapService } from "../../../../organisms/MapView/Services/TracesMapService/TracesMapService";
import TracesMapLayersContainer from "../TracesMapLayersContainer/TracesMapLayersContainer";

export interface ITracesMapProps {
  onMapLoad?: () => void;
}

const TracesMapContainer = (props: ITracesMapProps) => {
  const appConfig = getAppConfig();
  const isMobile = useMobileDetect();
  const { tokens } = useContext(AuthContext);
  const [map, setMap] = useState<TrimbleMaps.Map>();
  const [tracesMapService, setTracesMapService] = useState<TracesMapService>();

  /**************  CONFIGURATION **************/
  const mapConfig = {
    ...appConfig.map,
    locale: tokens?.profile
      ?.language as (typeof TrimbleMaps.Common.Language)[keyof typeof TrimbleMaps.Common.Language],
    isMobile: isMobile,
  };

  const handleOnMapLoad = (map: TrimbleMaps.Map) => {
    const mapService = new TracesMapService(map);
    setTracesMapService(mapService);
    setMap(map);
    props.onMapLoad?.();
  };

  return (
    <>
      <TracesMapLayersContainer map={map} tracesMapService={tracesMapService} />
      <div className="traces-map h-100 w-100">
        <MapComponent config={mapConfig} handleMapLoad={handleOnMapLoad} />
      </div>
    </>
  );
};

export default TracesMapContainer;
