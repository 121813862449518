import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as MapMenuIcon } from "../../../../assets/images/map_menu.svg";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { sendMonitoringLogs } from "../../../../common/utils";
import Card from "@trimbleinc/modus-react-bootstrap/esm/Card";
import withAuth from "@ttl/shared-react-library/src/auth/WithAuth";
import "./MapMenuContainer.scss";
import useOnOutsideClick from "@utilityjs/use-on-outside-click";
import MapMenu from "../MapMenu/MapMenu";
import TracesTaskView from "../../../application/molecules/TracesTaskView/TracesTaskView";

export interface IMapMenuContainer {
  zoomLevel?: number;
  showTasks?: boolean;
  handleTaskData?: (flag: boolean) => void;
}

export interface IMapMenuItems {
  label: string;
  title?: string;
  key: string;
  isChecked?: boolean;
  isDisabled?: boolean;
}

const MapMenuContainer = (props: IMapMenuContainer) => {
  const [showOptions, setShowOptions] = useState(false);
  const zoomLevelToShowLabels = 8;
  const VEHICLE_LABEL = "vehicleLabel";
  const [mapMenuItems, setMapMenuItems] = useState<IMapMenuItems[]>([
    {
      label: "TTM.followup.map.vehicleLabel",
      title: "TTM.followup.map.vehicleLabel.toolTipText",
      key: "vehicleLabel",
    },
  ]);

  type UpdateMapMenuItems = (key: string, newProps: Partial<IMapMenuItems>) => void;

  const { zoomLevel, showTasks } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [showTaskView, setShowTaskView] = useState<boolean>(false);

  const handleTaskData = (flag: boolean) => {
    setShowTaskView(flag);
    props?.handleTaskData && props.handleTaskData(flag);
  };
  useOnOutsideClick(ref, () => {
    setShowOptions(false);
  });

  const setShowMarkerLabelClass = (flag: boolean) => {
    if (flag && zoomLevel && zoomLevel >= zoomLevelToShowLabels) {
      document.getElementById("mapView")?.classList.add("show-marker-label");
    } else {
      document.getElementById("mapView")?.classList.remove("show-marker-label");
    }
  };

  const updateMapMenuItems: UpdateMapMenuItems = (key, newProps) => {
    setMapMenuItems((prevState) =>
      prevState.map((config) => (config.key === key ? { ...config, ...newProps } : config)),
    );
  };

  const handleMapMenuCheckBoxClick = (key: string, flag: boolean) => {
    switch (key) {
      case VEHICLE_LABEL:
        updateMapMenuItems(key, { isChecked: flag });
        setShowMarkerLabelClass(flag);
        sendMonitoringLogs(flag ? "VEHICLE_LABEL_CHECKED" : "VEHICLE_LABEL_UNCHECKED");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setShowMarkerLabelClass(
      mapMenuItems.find((item) => item.key === VEHICLE_LABEL)?.isChecked || false,
    );
  }, [zoomLevel]);

  return (
    <div ref={ref}>
      <MapMenuIcon
        title={i18nInstance.t("TTM.followup.map.settings")}
        className="map-menu map-menu-icon"
        onClick={() => {
          setShowOptions(!showOptions);
          sendMonitoringLogs(
            showOptions
              ? `${showTasks ? "HISTORY_" : "VEHICLES_"}MAP_MENU_OPEN`
              : `${showTasks ? "HISTORY_" : "VEHICLES_"}MAP_MENU_CLOSE`,
          );
        }}
      />
      {showOptions &&
        (showTasks ? (
          <TracesTaskView handleTaskData={handleTaskData} showTaskView={showTaskView} />
        ) : (
          <Card border="dark" className="shadow map-menu map-menu-container">
            <MapMenu
              mapMenuItems={mapMenuItems}
              handleMapMenuCheckBoxClick={handleMapMenuCheckBoxClick}
            />
          </Card>
        ))}
    </div>
  );
};

export default withAuth(MapMenuContainer);
