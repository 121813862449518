import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import { CustomIcons } from "../../../../../../../assets/ts/customIcons";
import { useAppStore } from "../../../../../../../contexts/app.context";
import {
  emptyFeatureCollection,
  modifyTraceSvgIcon,
} from "../../../../../../../common/tracesUtils";
import { useTraceStore } from "../../../../../../../contexts/traces.context";
import MapCustomPopup from "../../../../../../organisms/MapView/MapCustomPopup/MapCustomPopup";
import { ITimeRange, ITrace } from "../../../../../../../models/TraceModel";
import { TracesMapService } from "../../../../../../organisms/MapView/Services/TracesMapService/TracesMapService";
import {
  FeatureCollection,
  Geometry,
  GeoJsonProperties,
} from "@trimblemaps/trimblemaps-js/geojson";

export interface ITracesCustomIconLayerProps {
  map?: TrimbleMaps.Map;
  geoJSON?: FeatureCollection | null;
  timeRange?: ITimeRange;
  resetLayer?: boolean;
  tracesMapService?: TracesMapService;
  handleOnTraceSelect?: (trace: ITrace | null) => void;
  handleHighlightedTraces?: (highlightIds: string[]) => void;
}

export const TRACE_CUSTOM_ICON_SOURCE = "trace-custom-icon-source";
export const TRACE_CUSTOM_ICON_LAYER = "trace-custom-icon-layer";
export const TRACE_CUSTOM_ICON_LAYER_COUNT = "trace-custom-icon-layer-count";
export const TRACE_CUSTOM_ICON_UNCLUSTERED_POINTS = "trace-custom-icon-unclustered-points";

const TracesCustomIconLayer = (props: ITracesCustomIconLayerProps) => {
  const { map, resetLayer, geoJSON, tracesMapService, timeRange, handleOnTraceSelect } = props;

  const appStore = useAppStore();
  const traceStore = useTraceStore();

  const svgPathRf = useRef<Map<string, string>>(new Map());
  const popupRef = useRef<TrimbleMaps.Popup | null>(null);

  const geoJSONRef = useRef(emptyFeatureCollection);
  const timeRangeRef = useRef<ITimeRange | null>(null);

  /************** EVENT HANDLERS **************/
  const removePopup = () => {
    props?.handleHighlightedTraces?.([]);
    props?.handleOnTraceSelect?.(null);
    if (popupRef.current) {
      popupRef.current.remove();
      popupRef.current = null;
    }
  };

  /************** HANDLER METHODS **************/
  const handleClusterClick = (event: any) => {
    try {
      if (map && event) {
        handleOnTraceSelect?.(null);
        const features = map.queryRenderedFeatures(event.point, {
          layers: [TRACE_CUSTOM_ICON_LAYER],
        });
        if (!features.length) return;
        const clusterId = features[0].properties?.cluster_id;
        const source = map.getSource(TRACE_CUSTOM_ICON_SOURCE) as TrimbleMaps.GeoJSONSource;
        source?.getClusterLeaves(clusterId, 100, 0, (err, leaves) => {
          if (!err) {
            removePopup();
            const popupContainer = document.createElement("div");
            ReactDOM.render(
              // Unable to use store inside MapCustomPopup, so passing the required props
              <MapCustomPopup
                leaves={leaves}
                appStore={appStore}
                traceStore={traceStore}
                handleSelectedTrace={props?.handleOnTraceSelect}
              />,
              popupContainer,
            );
            popupRef.current = new TrimbleMaps.Popup({
              className: "map-custom-icon-popup",
            });
            popupRef?.current
              ?.setLngLat(leaves[0]?.properties?.coordinates)
              .setDOMContent(popupContainer)
              .addTo(map);
            const element = document.getElementsByClassName("trimblemaps-popup-close-button")[0];
            const handleCloseButtonClick = () => {
              removePopup();
              element?.removeEventListener("click", handleCloseButtonClick);
            };
            element?.addEventListener("click", handleCloseButtonClick);
          }
        });
      }
    } catch (error) {
      console.error("Error on handleClusterClick", error);
    }
  };

  const handleOnStyleChange = () => {
    removePopup();
    geoJSONRef && loadLayerIcons(geoJSONRef.current);
  };

  const handleOnCustomIconClick = useRef((e: any) => {
    const feature = e?.features?.[0];
    const trace: ITrace = {
      ...JSON.parse(feature?.properties?.trace),
      typeName: feature?.properties?.trace_type_name,
    };
    handleOnTraceSelect?.(trace);
  });

  /**************  EVENT LISTENERS **************/
  const addLayerEvents = () => {
    map?.on("zoom", removePopup);
    map?.on("style.load", handleOnStyleChange);
    map?.on("click", TRACE_CUSTOM_ICON_LAYER, handleClusterClick);
    map?.on("click", TRACE_CUSTOM_ICON_UNCLUSTERED_POINTS, handleOnCustomIconClick.current);
  };

  const removeLayerEvents = () => {
    map?.off("zoom", removePopup);
    map?.off("style.load", handleOnStyleChange);
    map?.off("click", TRACE_CUSTOM_ICON_LAYER, handleClusterClick);
    map?.off("click", TRACE_CUSTOM_ICON_UNCLUSTERED_POINTS, handleOnCustomIconClick.current);
  };

  /**************  LAYER METHODS **************/
  function addLayer(updatedGeoJSON: FeatureCollection<Geometry, GeoJsonProperties>) {
    try {
      if (map && !map?.getLayer(TRACE_CUSTOM_ICON_LAYER)) {
        //GeoJSON source with clustering enabled.
        tracesMapService?.addSource(TRACE_CUSTOM_ICON_SOURCE, updatedGeoJSON, {
          cluster: true,
          clusterRadius: 10,
          clusterMaxZoom: 14,
          maxzoom: 23,
        });

        //Layer for the clustered points.
        map.addLayer({
          id: TRACE_CUSTOM_ICON_LAYER,
          type: "circle",
          source: TRACE_CUSTOM_ICON_SOURCE,
          filter: ["has", "point_count"],
          paint: {
            "circle-color": "#217cbb",
            "circle-radius": 15,
          },
        });

        //Layer for the cluster count labels.
        map.addLayer({
          id: TRACE_CUSTOM_ICON_LAYER_COUNT,
          type: "symbol",
          source: TRACE_CUSTOM_ICON_SOURCE,
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-size": 12,
            "text-allow-overlap": true,
          },
          paint: {
            "text-color": "#f9f9fb",
          },
        });

        //Layer for the unclustered points.
        map.addLayer({
          id: TRACE_CUSTOM_ICON_UNCLUSTERED_POINTS,
          type: "symbol",
          source: TRACE_CUSTOM_ICON_SOURCE,
          filter: ["!", ["has", "point_count"]], // Show only unclustered points
          layout: {
            "icon-image": ["get", "icon_name"],
            "icon-allow-overlap": true,
          },
        });
      }
    } catch (error) {
      console.log("addLayer ~ error:", error);
    }
  }

  /**************  FILTER METHODS **************/
  const updateFilters = (applyClusterFilters: boolean) => {
    if (map) {
      if (applyClusterFilters) {
        map.setFilter(TRACE_CUSTOM_ICON_LAYER, ["has", "point_count"]);
        map.setFilter(TRACE_CUSTOM_ICON_LAYER_COUNT, ["has", "point_count"]);
        map.setFilter(TRACE_CUSTOM_ICON_UNCLUSTERED_POINTS, ["!", ["has", "point_count"]]);
      } else {
        map.setFilter(TRACE_CUSTOM_ICON_LAYER, null);
        map.setFilter(TRACE_CUSTOM_ICON_LAYER_COUNT, null);
        map.setFilter(TRACE_CUSTOM_ICON_UNCLUSTERED_POINTS, null);
      }
    }
  };

  const filterTraces = (filterIds: string[]) => {
    try {
      if (!map?.getLayer(TRACE_CUSTOM_ICON_LAYER)) return;
      const source = map?.getSource(TRACE_CUSTOM_ICON_SOURCE) as TrimbleMaps.GeoJSONSource;
      if (filterIds && filterIds.length > 0) {
        const idsSet = new Set(filterIds); // Fast lookup for IDs
        const filteredFeatures =
          geoJSONRef.current?.features.filter(
            (feature) => feature.properties && idsSet.has(feature.properties.id?.toString()),
          ) || [];
        // Update the data source with filtered features
        const filteredData: FeatureCollection<Geometry, GeoJsonProperties> = {
          type: "FeatureCollection",
          features: filteredFeatures,
        };
        source.setData(filteredData);
        updateFilters(true); // Apply cluster filters
      } else {
        source.setData(emptyFeatureCollection);
        updateFilters(false); // Remove filters
      }
    } catch (error) {
      console.error("handleOnFilterTraces ~ error:", error);
    }
  };

  const handleOnFilter = (timeRange?: ITimeRange) => {
    if (!timeRange) return;
    const filteredFeaturesIds = geoJSONRef.current.features
      .filter(
        (feature) =>
          feature?.properties?.timestamp >= timeRange?.startTime &&
          feature?.properties?.timestamp <= timeRange?.endTime,
      )
      .map((feature) => feature?.properties?.id?.toString());
    filterTraces(filteredFeaturesIds);
  };

  /************** UTIL METHODS **************/
  const loadSvgWithColor = (svgPath: string, color: string) => {
    return new Promise((resolve) => {
      const cachedSvgText = svgPathRf.current.get(svgPath);
      if (cachedSvgText) {
        modifyTraceSvgIcon(cachedSvgText, color, resolve);
      } else {
        fetch(svgPath)
          .then((response) => response.text())
          .then((svgText) => {
            svgPathRf.current.set(svgPath, svgText);
            modifyTraceSvgIcon(svgText, color, resolve);
          });
      }
    });
  };
  async function loadLayerIcons(geoJSON: FeatureCollection<Geometry, GeoJsonProperties>) {
    const iconPromises = geoJSON.features.map(async (feature) => {
      const icon = CustomIcons.find((icon) => {
        return icon.name === feature?.properties?.custom_icon;
      });

      if (icon) {
        const img = await loadSvgWithColor(icon.src, feature?.properties?.activity_icon);
        const iconName = feature?.properties?.icon_name;
        if (map && !map.hasImage(iconName)) {
          map.addImage(iconName, img as HTMLImageElement);
        }
      }
    });
    await Promise.all(iconPromises);
    addLayer(geoJSON);
    geoJSONRef.current = geoJSON;
    addLayerEvents();

    const { startTime, endTime } = timeRangeRef.current || {};
    if (startTime && endTime) {
      handleOnFilter({ startTime, endTime });
    }
  }

  /************** CLEANUP METHODS **************/
  const onCleanup = () => {
    removeLayerEvents();
    removePopup();
    geoJSONRef.current = emptyFeatureCollection;
    tracesMapService?.removeLayer(TRACE_CUSTOM_ICON_LAYER);
    tracesMapService?.removeLayer(TRACE_CUSTOM_ICON_LAYER_COUNT);
    tracesMapService?.removeLayer(TRACE_CUSTOM_ICON_UNCLUSTERED_POINTS);
    tracesMapService?.updateSource(TRACE_CUSTOM_ICON_SOURCE, emptyFeatureCollection);
  };

  /************** LIFECYCLE METHODS **************/
  useEffect(() => {
    if (geoJSON && geoJSON.features.length > 0) {
      loadLayerIcons(geoJSON);
    } else {
      onCleanup();
    }
  }, [geoJSON]);

  useEffect(() => {
    popupRef.current?.remove();
    if (timeRange) {
      timeRangeRef.current = timeRange;
      handleOnFilter(timeRange);
    }
  }, [timeRange]);

  useEffect(() => {
    if (resetLayer) {
      onCleanup();
    }
  }, [resetLayer]);

  return <></>;
};

export default TracesCustomIconLayer;
