import React from "react";
import "./VehicleZones.scss";
import { IZone } from "../../../../../models/VehicleModel";
import {
  getTranslatedValue,
  getZoneTemp,
  isValidZoneData,
  isWarning,
} from "../../../../../common/utils";
import Dash from "../../../../atoms/Dash/Dash";
import ZoneDetails from "../ZoneDetails/ZoneDetails";
import Setpoint from "../../../../../assets/images/setpoint.svg";
import ZoneCardView from "../ZoneCardView/ZoneCardView";
import { ViewMode } from "../../../../../common/constants";

export interface IVehicleZonesProps {
  zones: IZone[];
  viewMode?: ViewMode;
}
const defaultProps: IVehicleZonesProps = {
  zones: [],
  viewMode: ViewMode.LIST,
};
const vehicleZones = (props: IVehicleZonesProps) => {
  const { zones, viewMode = ViewMode.LIST } = props;
  const zoneList =
    zones && zones.length > 0 ? (
      zones.map((zoneItem: IZone, index: number) => {
        if (!isValidZoneData(zoneItem)) return null;
        const {
          zone: zoneLabel,
          temp1,
          temp2,
          dischargeAir,
          returnAir,
          doorInfo,
          setpoint,
        } = zoneItem;
        const warningStyle = isWarning(temp1) || isWarning(temp2) ? "warning" : "";
        const separator = zoneLabel ? ":" : "";
        return (
          <div key={`${index + zoneLabel}`} className={`zone ${warningStyle}`}>
            {zoneLabel && (
              <div className="zone-container">
                <div className={`d-flex flex-row truncate-text zone-label`}>
                  <div title={`${zoneLabel}`}>{zoneLabel}</div>
                  {viewMode === ViewMode.CARD && doorInfo?.doorStatus != null ? (
                    <ZoneDetails doorStatus={doorInfo.doorStatus} timestamp={doorInfo.timestamp} />
                  ) : null}
                </div>
                {viewMode === ViewMode.CARD && setpoint != null && (
                  <div className="zone-setpoint">
                    <img
                      src={Setpoint}
                      title={getTranslatedValue("TTM.followup.", `setpoint`)}
                      className="setpoint-icon mr-1"
                    />
                    <span title={getZoneTemp(setpoint)}>{getZoneTemp(setpoint)}</span>
                  </div>
                )}
              </div>
            )}
            {viewMode !== ViewMode.CARD ? separator : null}
            {viewMode !== ViewMode.CARD ? (
              <ZoneDetails
                warning={isWarning(temp1)}
                temp={getZoneTemp(temp1?.temp)}
                timestamp={temp1?.timestamp}
              />
            ) : (
              <ZoneCardView
                temp1={temp1}
                temp2={temp2}
                dischargeAir={dischargeAir}
                returnAir={returnAir}
              />
            )}
          </div>
        );
      })
    ) : (
      <Dash />
    );
  return <div className={`d-flex flex-row zone-wrapper row-gap ${viewMode}`}>{zoneList}</div>;
};
vehicleZones.defaultProps = defaultProps;
export default vehicleZones;
