import React, { useEffect, useState } from "react";
import "./MapCustomPopup.scss";
import moment from "moment";
import TraceViewDetailsPopup from "../../../application/molecules/TraceViewDetailsPopup/TraceViewDetailsPopup";
import { DATE_TIME_FORMAT } from "../../../../common/constants";
import { AppStore } from "../../../../stores/appStore/app.store";
import { TraceStore } from "../../../../stores/traceStore/trace.store";
import { modifyCustomSvgIcon } from "../../../../common/tracesUtils";
import { CustomIcons } from "../../../../assets/ts/customIcons";
import { ITrace } from "../../../../models/TraceModel";

export interface IMapCustomPopup {
  leaves: any;
  appStore: AppStore | null;
  traceStore: TraceStore | null;
  handleSelectedTrace?: (selectedTrace: ITrace | null) => void;
}

const MapCustomPopup = (props: IMapCustomPopup) => {
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [imgSrcCache, setImgSrcCache] = useState<{ [key: string]: string }>({});

  const handleItemClick = (item: any) => {
    setSelectedItem(item);
    props?.handleSelectedTrace?.(item.properties.trace);
  };

  const fetchTraceIcon = async (leaf: any) => {
    const customIconName = leaf?.properties?.custom_icon;
    const icon = CustomIcons.find((icon: { name: string; src: string }) => {
      return icon.name === customIconName;
    });

    if (icon) {
      const response = await fetch(icon.src);
      const svgText = await response.text();
      const modifiedSvgText = modifyCustomSvgIcon(svgText, leaf?.properties?.activity_icon);
      setImgSrcCache((prev) => ({ ...prev, [leaf?.properties?.trace?.id]: modifiedSvgText }));
    }
  };

  const fetchIcons = async () => {
    for (const leaf of props.leaves) {
      await fetchTraceIcon(leaf);
    }
  };

  const sortLeaveUsingTimestamp = () => {
    props.leaves.sort((a: any, b: any) => {
      return moment(b.properties.timestamp).diff(moment(a.properties.timestamp));
    });
  };

  useEffect(() => {
    //fetch icons for all leaves and sort the leaves based on the timestamp on mount
    fetchIcons();
    sortLeaveUsingTimestamp();
  }, [props.leaves]);

  return (
    <div className="map-custom-popup-container">
      {!selectedItem ? (
        props.leaves.map((leaf: any) => (
          <div
            className="custom-popup-item truncate-text"
            title={leaf.properties.trace_type_name}
            key={leaf.properties.id}
            onClick={() => handleItemClick(leaf)}
          >
            <div className="custom-popup-item-content">
              <img
                src={imgSrcCache[leaf?.properties?.trace?.id]}
                className="custom-popop-item-icon"
              />
              <span className="ml-1 custom-popup-item-name truncate-text">
                {leaf.properties.trace_type_name}
              </span>
            </div>
            <span className="custom-popup-item-timestamp">
              {moment(leaf.properties.timestamp).format(DATE_TIME_FORMAT)}
            </span>
          </div>
        ))
      ) : (
        <TraceViewDetailsPopup
          trace={selectedItem.properties.trace}
          terminalId={props?.traceStore?.traceFilter?.terminalId}
          traceTypeName={selectedItem.properties.trace_type_name}
          activityTypes={props?.appStore?.activityTypes ? props.appStore.activityTypes : []}
          traceTypes={props?.traceStore?.traceTypes ? props.traceStore.traceTypes : []}
          selectedView={props?.traceStore?.selectedView}
          showCustomView={true}
          onBack={() => {
            props?.handleSelectedTrace?.(null);
            setSelectedItem(null);
          }}
        />
      )}
    </div>
  );
};

export default MapCustomPopup;
