import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import "./MessageConversations.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IMessageError, IMessageThread, INewSendMessage } from "../../../models/MessageModel";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import MessageService from "../../../services/Message.service";
import ConversationThread from "../ConversationThread/ConversationThread";
import NewConversationThread from "../NewConversationThread/NewConversationThread";
import errorIcon from "../../../assets/images/error_icon.svg";
import Preloader from "@ttl/shared-react-library/src/components/Preloader/Preloader";
import ConversationHeader from "../../molecules/ConversationHeader/ConversationHeader";
import { useAppStore } from "../../../contexts/app.context";
import { useDidMountEffect } from "../../../hooks/useDidMountEffect/useDidMountEffect";
import { IVehicle } from "../../../models/VehicleModel";
import { PATH } from "../../../common/constants";

export interface IMessageConversationProps {
  unitId?: string;
  showVehicleHeader?: boolean;
  driverName?: string;
}

const MessageConversations = observer((props: IMessageConversationProps) => {
  const messageService = new MessageService();

  const params = useParams();
  const appStore = useAppStore();
  const navigate = useNavigate();
  const { hash } = useLocation();

  const conversationRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [showNewThread, setShowNewThread] = useState<boolean>(false);
  const [messageThreads, setMessageThreads] = useState<IMessageThread[]>([]);

  const [terminalId, setTerminalId] = useState<string>("");
  const [activeThreadId, setActiveThreadId] = useState<string>("");

  const [sendMsgLoading, setSendMsgLoading] = useState<boolean>(false);
  const [msgErrorObj, setMsgErrorObj] = useState<IMessageError | undefined>(undefined);
  const [vehicleData, setVehicleData] = useState<IVehicle>();
  const [msgUpdatedTime, setMsgUpdatedTime] = useState<number>(0);
  const [newThreadOpen, setNewThreadOpen] = useState<boolean>(false);

  const handleSetActiveThreadId = (threadId: string) => {
    setActiveThreadId(threadId);
  };

  const scrollToBottom = () => {
    try {
      const scrollTimer = setTimeout(() => {
        if (conversationRef && conversationRef.current) {
          conversationRef.current.scrollBy?.({
            top: conversationRef.current.scrollHeight,
            behavior: "smooth",
          });
        }
        clearTimeout(scrollTimer);
      }, 50);
    } catch (error) {
      console.log("MessageConversations.tsx~ scrollToBottom ~ error:", error);
    }
  };

  const handleNewThreadOpen = () => {
    setShowNewThread(true);
    scrollToBottom();
  };

  const handleNewThreadClose = () => {
    if (showNewThread) {
      if (hash && hash.length > 0) {
        navigate(`${PATH.MESSAGES}/unit/${params?.unitId}`);
      }
      setShowNewThread(false);
      setNewThreadOpen(false);
    }
  };

  const loadMessageThread = (terminalId: string) => {
    setError(false);
    messageService
      .getVehicleMessageThreads(terminalId)
      .then((response) => {
        setLoading(false);
        setMessageThreads(response.data);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  const fileUpload = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    return messageService
      .uploadFile(formData)
      .then((uploadResponse) => {
        return uploadResponse && uploadResponse?.data?.fileId;
      })
      .catch((e) => {
        setMsgErrorObj({
          error: true,
          message:
            i18nInstance.t("TTM.followup.messages.attachment.generic.error") +
            `  ${e?.response?.data}`,
        });
        setSendMsgLoading(false);
      });
  };

  const sendMessage = (newMsg: INewSendMessage) => {
    messageService
      .sendMessage(terminalId, newMsg)
      .then(() => {
        setMsgErrorObj({ error: false });
        setSendMsgLoading(false);
        const CURRENT_TIME = new Date().getTime();
        setMsgUpdatedTime(CURRENT_TIME);
        appStore?.setMsgLastUpdatedTime(CURRENT_TIME);
        loadMessageThread(terminalId);
        handleNewThreadClose();
      })
      .catch((e) => {
        setMsgErrorObj({ error: true, message: i18nInstance.t("TTM.followup.generic.error") });
        setSendMsgLoading(false);
      });
  };

  const handleOnSend = async (newMessage: INewSendMessage) => {
    setSendMsgLoading(true);
    setMsgErrorObj(undefined);
    if (newMessage?.file) {
      const fileRef: string = await fileUpload(newMessage.file);
      if (fileRef) {
        newMessage.body && (newMessage.body += ` [${newMessage.file.name}|doc://${fileRef}]`);
        delete newMessage?.file;
        sendMessage(newMessage);
      } else {
        setMsgErrorObj({
          error: true,
          message: i18nInstance.t("TTM.followup.messages.attachment.generic.error"),
        });
      }
    } else {
      sendMessage(newMessage);
    }
  };

  const getVehicleDetails = () => {
    const unitId = params?.unitId || props.unitId;

    appStore?.vehiclesList?.map((vehicle) => {
      if (unitId == vehicle.id) {
        setVehicleData(vehicle.vehicle);
      }
    });
  };
  useEffect(() => {
    if (newThreadOpen || (hash && hash.length > 0)) {
      handleNewThreadOpen();
    } else {
      handleNewThreadClose();
    }
  }, [newThreadOpen, hash]);

  useEffect(() => {
    setLoading(true);
    setSendMsgLoading(false);
    setMsgErrorObj(undefined);
    setActiveThreadId("");

    const unitId = params?.unitId || props.unitId;

    if (unitId) {
      setTerminalId(unitId);
      setMessageThreads([]);
      loadMessageThread(unitId);
      getVehicleDetails();
    }
  }, [params?.unitId || props.unitId]);

  useEffect(() => {
    getVehicleDetails();
  }, [appStore?.vehiclesList]);
  useLayoutEffect(() => {
    scrollToBottom();
  }, [messageThreads]);
  useDidMountEffect(() => {
    if (
      terminalId &&
      appStore?.msglastUpdatedTime &&
      appStore?.msglastUpdatedTime > msgUpdatedTime &&
      appStore?.vehiclesNotificationsList.includes(terminalId)
    ) {
      loadMessageThread(terminalId);
      appStore?.setVehiclesNotificationsList([]);
    }
  }, [appStore?.msglastUpdatedTime]);
  return (
    <>
      {vehicleData && (
        <ConversationHeader
          vehicle={vehicleData}
          isNewThreadBtnDisabled={loading || error || showNewThread}
          setNewThreadOpen={setNewThreadOpen}
          showVehicleHeader={props.showVehicleHeader}
          driverName={props.driverName}
        />
      )}
      <div
        ref={conversationRef}
        className={`conversation-threads-container ${
          loading || error ? "conversation-threads-info" : ""
        }`}
      >
        {showNewThread && !loading && !error ? (
          <NewConversationThread
            msgError={msgErrorObj}
            activeThreadId={activeThreadId}
            sendMsgLoading={sendMsgLoading}
            onClose={handleNewThreadClose}
            onSendMessage={handleOnSend}
            setActiveThreadId={handleSetActiveThreadId}
          />
        ) : null}
        {loading ? (
          <Preloader />
        ) : (
          <>
            {error ? (
              <div className="conversation-threads-error">
                <img src={errorIcon} />
                <span className="pt-2">
                  {i18nInstance.t("TTM.followup.messages.conversationError")}
                </span>
                <span>{i18nInstance.t("TTM.followup.generic.reload")}</span>
              </div>
            ) : (
              <div className="d-flex flex-column">
                {messageThreads &&
                  messageThreads.length > 0 &&
                  messageThreads.map((thread: IMessageThread) => {
                    return (
                      <ConversationThread
                        key={thread.id}
                        thread={thread}
                        terminalId={terminalId}
                        msgError={msgErrorObj}
                        sendMsgLoading={sendMsgLoading}
                        activeThreadId={activeThreadId}
                        onSendMessage={handleOnSend}
                        setActiveThreadId={setActiveThreadId}
                      />
                    );
                  })}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
});

export default MessageConversations;
