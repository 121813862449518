import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { MapService } from "../../../organisms/MapView/Services/MapService";
import Preloader from "@ttl/shared-react-library/src/components/Preloader/Preloader";
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import { LngLat } from "../../../../models/VehicleModel";
import { coordinateToDegree, loadScript, loadStyleSheet } from "../../../../common/utils";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import MapLayerStyleContainer from "../../../organisms/MapView/MapLayerStyleContainer/MapLayerStyleContainer";
import { usePersistedMapStyleAndLayer } from "../../../../hooks/usePersistedMapStyleAndLayer/usePersistedMapStyleAndLayer";

export interface IMapConfig {
  apiKey: string;
  defaultMapLocation: LngLat;
  searchApi?: string;
  routeReportApi?: string;
  cdnBaseUrl: string;
  scriptFile: string;
  styleSheet: string;
  isMobile: boolean;
  locale: (typeof TrimbleMaps.Common.Language)[keyof typeof TrimbleMaps.Common.Language];
}
export interface IMapComponentProps {
  config: IMapConfig;
  handleMapLoad?: (map: TrimbleMaps.Map) => void;
}

const MapComponent = (props: IMapComponentProps) => {
  const { config } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [mapService, setMapService] = useState<MapService>();

  usePersistedMapStyleAndLayer({ mapService });

  const mapLoadedCallback = (mapServiceObj: MapService) => {
    setLoading(false);
    props?.handleMapLoad?.(mapServiceObj?.getMap());
    mapServiceObj?.getMap()?.addControl(new TrimbleMaps.FullscreenControl());
  };

  const initMapService = () => {
    try {
      const mapService = new MapService();
      mapService.initMap({
        apiKey: config.apiKey,
        container: "map",
        region: TrimbleMaps.Common.Region.WW,
        language: config.locale || TrimbleMaps.Common.Language.EN,
        center: new TrimbleMaps.LngLat(
          coordinateToDegree(config.defaultMapLocation.lon),
          coordinateToDegree(config.defaultMapLocation.lat),
        ),
        isMobile: config.isMobile,
      });
      setMapService(mapService);
      mapService.getMap()?.on("load", () => mapLoadedCallback(mapService));
    } catch (error) {
      console.log("initMapService ~ error:", error);
    }
  };

  const loadMapScript = async () => {
    try {
      await loadScript(`${config.cdnBaseUrl}/${config.scriptFile}`, "map-JS");
    } catch (error) {
      console.log("Error in loading CDN map library reference", error);
      await loadScript(`${process.env.PUBLIC_URL}/assets/js/${config.scriptFile}`, "map-JS");
    }
  };

  const loadMapStyle = async () => {
    try {
      await loadStyleSheet(`${config.cdnBaseUrl}/${config.styleSheet}`, "map-CSS");
    } catch (error) {
      console.log("Error in loading CDN map style reference", error);
      await loadStyleSheet(`${process.env.PUBLIC_URL}/assets/css/${config.styleSheet}`, "map-CSS");
    }
  };

  useEffect(() => {
    if (mapService && config.locale) {
      mapService.setLanguage(config.locale);
    }
  }, [config.locale]);

  const init = async () => {
    try {
      if (!mapService) {
        setLoading(true);
        await loadMapScript();
        loadMapStyle();
        initMapService();
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("map component ~ init ~ error:", error);
    }
  };

  // Initialize useEffect.
  useLayoutEffect(() => {
    init();
    return () => {
      try {
        mapService?.getMap()?.off("load", mapLoadedCallback);
      } catch (error) {
        console.log(error);
      }
    };
  }, []);

  return (
    <div id="map" className="map-container" style={{ width: "100%", height: "100%" }}>
      {loading && (
        <div className="loader-container">
          <div>
            <Preloader />
          </div>
          <div className="loader-message">{i18nInstance.t("TTM.followup.map.loading")}</div>
        </div>
      )}
      {!loading && mapService && <MapLayerStyleContainer mapService={mapService} />}
    </div>
  );
};

export default MapComponent;
